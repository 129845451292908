import React from 'react';
import { injectIntl } from 'react-intl';

import Header from 'mui-mobile-components/header';
import Fields from 'mui-mobile-components/fields/fieldtab/Fields';

const ManageFields = ({ intl, location }) => (
  <div className={`${'main-content-mobile'}`}>
    {(location.pathname).includes('managefields') &&
    <Header bcTitle={intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_2' })} />}
    {(location.pathname).includes('fertilization') &&
    <Header bcTitle={intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_3' })} />}
    {(location.pathname).includes('soilsamplesimport') &&
    <Header bcTitle={intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_4' })} />}
    <Fields />
  </div>
);

export default injectIntl(ManageFields);
