import * as React from 'react';
import { Field } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import RadioButton from '@tine/lib-frontend-components/components/inputs/radio-button';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import ToggleButton from 'react-toggle-button';
import DatePicker from 'react-datepicker';
import { addMonths, subDays } from 'date-fns';

import TextBoxControl from 'components/forms/TextBoxControl';
import SelectControl from 'components/forms/SelectBoxControl';
import { getYears, getHarvest, getDropDownFieldsStatuses, getDropDownValues, getDropDownMethod } from 'utils/index';

function getYieldResponseDataHarvest(yieldPlan, changeYieldValueByCalculation, intl) {
  if (yieldPlan.plannedYields?.length > 0) {
    let labelName1 = intl.formatMessage({ id: 'FIRST_HARVEST_YIELD' });
    let labelName2 = intl.formatMessage({ id: 'SECOND_HARVEST_YIELD' });
    let labelName3 = intl.formatMessage({ id: 'THIRD_HARVEST_YIELD' });
    let labelName4 = intl.formatMessage({ id: 'FOURTH_HARVEST_YIELD' });
    return yieldPlan.plannedYields.map((plannedYield,index) => (
      (index < yieldPlan.harvests) ?
      <div key={`yiedHarvest_${index}`} className="harvestText">
        <div>
          <EmphasisLight size="small">
            {index === 0 ? labelName1 : ''}
            {index === 1 ? labelName2 : ''}
            {index === 2 ? labelName3 : ''}
            {index === 3 ? labelName4 : ''}
          </EmphasisLight>
        </div>          
        <div>
          <span>
            <input
              className="input-textbox-field"
              name={`yiedHarvest_${index}`}
              id={`yiedHarvest_${index}`}
              type="text"
              value={plannedYield.yield}
              onChange={event => changeYieldValueByCalculation(event)}
            />
          </span>
          <span className="unit-style">kg ts/daa</span>
        </div>
      </div>
      : ''
    ))
  }
}

function getYieldResponseDataPercentage(yieldPlan, changeYieldValueByCalculation, intl) {
  if (yieldPlan.plannedYields?.length > 0) {
    let labelName1 = intl.formatMessage({ id: 'FIRST_HARVEST_YIELD_PERCENTAGE' });
    let labelName2 = intl.formatMessage({ id: 'SECOND_HARVEST_YIELD_PERCENTAGE' });
    let labelName3 = intl.formatMessage({ id: 'THIRD_HARVEST_YIELD_PERCENTAGE' });
    let labelName4 = intl.formatMessage({ id: 'FOURTH_HARVEST_YIELD_PERCENTAGE' });
    return yieldPlan.plannedYields.map((plannedYield,index) => (
      (index < yieldPlan.harvests) ?
      <div key={`yiedHarvestPercent_${index}`} className="harvestText">
        <div>
          <EmphasisLight size="small">
            {index === 0 ? labelName1 : ''}
            {index === 1 ? labelName2 : ''}
            {index === 2 ? labelName3 : ''}
            {index === 3 ? labelName4 : ''}
          </EmphasisLight>
        </div>          
        <div>
          <span>
            <input
              className="input-textbox-field"
              name={`yiedHarvestPercent_${index}`}
              id={`yiedHarvestPercent_${index}`}
              type="text"
              value={plannedYield.yieldPercent}
              onChange={event => changeYieldValueByCalculation(event)}
            />
          </span>
          <span className="unit-style">%</span>
        </div>
      </div>
      : ''
    ))
  }
}

function getYieldResponseData(yieldPlan,yieldSumValue,yieldPercentValue, changeYieldValueByCalculation, intl) {  
  return (
    yieldPlan.totalYield > 0 ?
    <div className="full-width field-yield-harvest">
      <div className="field-option">
        <div className="full-width insight-data-bottom paddingBottom">
          {getYieldResponseDataHarvest(yieldPlan, changeYieldValueByCalculation, intl)}
          <div className="harvestTextLow">
            <div className="topFont">{intl.formatMessage({ id: 'YIELD_SUM' })}</div>
            <div className="paddingTop">
              <span className="bottomFont">{yieldSumValue}</span>
              <span className="unit-style">kg ts/daa</span>
            </div>
          </div>
        </div>
        <div className="full-width insight-data-bottom">
          {getYieldResponseDataPercentage(yieldPlan, changeYieldValueByCalculation, intl)}
          <div className="harvestTextLow">
            <div className="topFont">{intl.formatMessage({ id: 'YIELD_TOTAL' })}</div>
            <div className="paddingTop">
              <span className="bottomFont">{yieldPercentValue}</span>
              <span className="unit-style">%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    : ''
  )
}

const ManureData = ({
  fallAdd,
  summerAdd,
  springAdd,
  manureApplied,
  onManureOptionChange,
  onManureDataChange,
  lblPage,
  manureNames,
  intl,
  showSoilSample,
  seasons,
  fieldMethod,
  fieldMasterData,
  yieldGoalValue,
  harvestValue,
  setYieldGoal,
  setHarvest,
  calculateYield,
  showHarvest,
  changedYieldValue,
  yieldSumValue,
  yieldPercentValue,
  changeYieldValueByCalculation,
  yieldCalculation,
  defaultYieldValue,
  fieldCalculationOnUpdate,
  toggleFieldCalculationOnUpdate,
  onFieldOptionChange,
  fieldOptionApplied,
  cut1Date,
  cut2Date,
  cut3Date,
  cut4Date,
  cut1DateChange,
  cut2DateChange,
  cut3DateChange,
  cut4DateChange,
  calculateHistoricalData,
  paramId,
  disableGrain,
  stravRemovedApplied,
  onStravRemovedChange,
  cut1show,
  cut2show,
  cut3show,
  cut4show,
  resetSeason
}) => (
  <div>
    <div className="full-width manure-details">
      <div className="full-width insight-data">
        <div>
          <Field
            name="previousCrop"
            controlId="previousCrop"
            label={lblPage.labels.previouscrop}
            disabled={false}
            styleName="select-box-common"
            component={SelectControl}
            options={getDropDownFieldsStatuses(disableGrain === true ? fieldMasterData.previousCropList : fieldMasterData.cropList)}
            placeholder=""
          />
        </div>
        <div>
          <Field
            name="seedingYear"
            controlId="seedingYear"
            label={lblPage.labels['seeding year']}
            disabled={false}
            styleName="select-box-common"
            component={SelectControl}
            options={getYears(1970)}
            placeholder=""
          />
        </div>
        <div>
          <Field
            name="clover"
            label={lblPage.labels.clover}
            component={TextBoxControl}
            controlId="clover"
            type="text"
            units={lblPage.labels.cloverUnit}
          />
        </div>
        <div>
          <div className='field-organic-style field-calculation-padding-top'>
            {intl.formatMessage({ id: 'FIELD_ORGANIC_LABEL' })}
          </div>
          {showSoilSample ?
          <div className='field-calculation-padding-top'>
            <ToggleButton
              label={intl.formatMessage({ id: 'MINERALIZATION_CALCULATION_NO' })}
              inactiveLabel={intl.formatMessage({ id: 'MINERALIZATION_CALCULATION_NO' })}
              activeLabel={intl.formatMessage({ id: 'MINERALIZATION_CALCULATION_YES' })}
              colors={{
                inactive: {
                  base: 'rgb(42, 60, 99)',
                  hover: 'rgb(42, 60, 99)',
                }
              }}
              value={fieldCalculationOnUpdate}
              onToggle={toggleFieldCalculationOnUpdate}
            />
          </div> :
          <div className='field-calculation-padding-top'>
            <span className="field-option__yes-no">
              <RadioButton
                id="field_option_yes"
                onChange={() => onFieldOptionChange(true)}
                labelText={lblPage.labels.yes}
                labelTextHidden
                disabled={false}
                name="fieldOptionApplied"
                size="small"
                checked={fieldOptionApplied === true}
              >
                <EmphasisLight size="small"> {lblPage.labels.yes}</EmphasisLight>
              </RadioButton>
            </span>
            <span className="field-option__yes-no">
              <RadioButton
                id="field_option_no"
                onChange={() => onFieldOptionChange(false)}
                labelText={lblPage.labels.no}
                labelTextHidden
                disabled={false}
                name="fieldOptionApplied"
                size="small"
                checked={fieldOptionApplied === false}
              >
                <EmphasisLight size="small"> {lblPage.labels.no}</EmphasisLight>
              </RadioButton>
            </span>
          </div>}
        </div>
      </div>
    </div>
    {yieldCalculation && (
    <div className="full-width manure-details">
      <div className={`full-width ${disableGrain === true ? 'insight-data-new-radio padding-bottom-15px' : 'insight-data-new padding-bottom-15px'}`}>
        {disableGrain === true && 
        <div>
          <div className='field-removal-style field-calculation-padding-top'>
            {intl.formatMessage({ id: 'REMOVAL_OF_STRAV' })}
          </div>
          <div className='field-calculation-padding-top'>
            <span className="field-option__yes-no">
              <RadioButton
                id="strav_removed_yes"
                onChange={() => onStravRemovedChange(true)}
                labelText={lblPage.labels.yes}
                labelTextHidden
                disabled={false}
                name="stravRemovedApplied"
                size="small"
                checked={stravRemovedApplied === true}
              >
                <EmphasisLight size="small"> {lblPage.labels.yes}</EmphasisLight>
              </RadioButton>
            </span>
            <span className="field-option__yes-no">
              <RadioButton
                id="strav_removed_no"
                onChange={() => onStravRemovedChange(false)}
                labelText={lblPage.labels.no}
                labelTextHidden
                disabled={false}
                name="stravRemovedApplied"
                size="small"
                checked={stravRemovedApplied === false}
              >
                <EmphasisLight size="small"> {lblPage.labels.no}</EmphasisLight>
              </RadioButton>
            </span>
          </div>
        </div>}
        <div>
          <span className="floatLeft">
            <Field
              name="yieldGoal"
              label={lblPage.labels.yieldGoal}
              component={TextBoxControl}
              controlId="yieldGoal"
              onChange={value => setYieldGoal(value)}
              type="text"
            />
          </span>
          <span className="spanUnit">
            {disableGrain === false ? lblPage.labels.yieldUnit : intl.formatMessage({ id: 'YIELD_UNIT' })}
          </span>
        </div>
        {disableGrain === false &&
        <div>
          <Field
            name="harvests"
            controlId="harvests"
            label={lblPage.labels.harvests}
            disabled={false}
            styleName="select-box-common width-50-percent"
            component={SelectControl}
            options={getHarvest(4)}
            onChange={value => setHarvest(value)}
            placeholder=""
          />
        </div>}
      </div>
      {showHarvest && disableGrain === false &&
      <div>
        {/*getYieldResponse(changedYieldValue, changeYieldValueByCalculation, intl, defaultYieldValue)*/}
        {getYieldResponseData(changedYieldValue,yieldSumValue,yieldPercentValue, changeYieldValueByCalculation, intl)}
      </div> }
    </div>
    )}
    <div className="full-width manure-details">
      <div className="full-width harvest-data-top">
        {cut1show && 
        <div>
          <div className="datePickerTextbox">
          {disableGrain === true && <FormattedMessage id="CUT_HARVEST" /> }
          {disableGrain === false && <FormattedMessage id="CUT1_HARVEST" /> }
          </div>
          <DatePicker  
            selected={cut1Date ? new Date(cut1Date) : null}
            onChange={cut1DateChange}
            dateFormat="dd-MM"
            dropdownMode="select" 
            name="cut1Date"
            fixedHeight
            minDate={new Date("01-01-"+new Date().getFullYear())}
            maxDate={subDays(addMonths(new Date("01-01-"+new Date().getFullYear()), 12),1)} 
          />
        </div>}
        {disableGrain === false && cut2show &&
        <div>
          <div className="datePickerTextbox">
            <FormattedMessage id="CUT2_HARVEST" />
          </div>
          <DatePicker  
            selected={cut2Date ? new Date(cut2Date) : null}
            onChange={cut2DateChange}
            dateFormat="dd-MM"
            dropdownMode="select" 
            name="cut2Date"
            fixedHeight
            minDate={new Date("01-01-"+new Date().getFullYear())}
            maxDate={subDays(addMonths(new Date("01-01-"+new Date().getFullYear()), 12),1)} 
          />
        </div>}
        {disableGrain === false && cut3show &&
        <div>
          <div className="datePickerTextbox">
            <FormattedMessage id="CUT3_HARVEST" />
          </div>
          <DatePicker  
            selected={cut3Date ? new Date(cut3Date) : null}
            onChange={cut3DateChange}
            dateFormat="dd-MM"
            dropdownMode="select" 
            name="cut3Date"
            fixedHeight
            minDate={new Date("01-01-"+new Date().getFullYear())}
            maxDate={subDays(addMonths(new Date("01-01-"+new Date().getFullYear()), 12),1)} 
          />
        </div>}
        {disableGrain === false && cut4show &&
        <div>
          <div className="datePickerTextbox">
            <FormattedMessage id="CUT4_HARVEST" />
          </div>
          <DatePicker  
            selected={cut4Date ? new Date(cut4Date) : null}
            onChange={cut4DateChange}
            dateFormat="dd-MM"
            dropdownMode="select" 
            name="cut4Date"
            fixedHeight
            minDate={new Date("01-01-"+new Date().getFullYear())}
            maxDate={subDays(addMonths(new Date("01-01-"+new Date().getFullYear()), 12),1)} 
          />
        </div>}
        {/*(cut1show || cut2show || cut3show || cut4show) && 
        <div className="yieldPaddingTopMin">
          <div className="button-style-cutdate">
            {paramId !== NEW &&
            <span
              className="yield-calculation-button-border"
              onClick={() => calculateHistoricalData()}
            >
              {intl.formatMessage({ id: 'CALCULATE_HISTORICAL_DATA' })}
            </span>}
            {paramId === NEW &&
            <span
              className="yield-calculation-button-border-grey"
            >
              {intl.formatMessage({ id: 'CALCULATE_HISTORICAL_DATA' })}
            </span>}
          </div>
            </div>*/}
      </div>
    </div>
    <div>
    <div className="full-width manure-storage">
      <div className="manure-option-right">
        <div className="manure-storage-data">
          <div><p className="manure-heading">&nbsp;</p></div>
          <div className="full-width manure-flex">
            <div className="full-width manure-inline-flex">
              <div className="div-width">
                <div className="full-width manure-method-data-value">
                  <div>
                    <Field
                      name="method"
                      controlId="method"
                      label={lblPage.labels.method}
                      styleName="select-box-common"
                      component={SelectControl}
                      options={getDropDownMethod(fieldMethod)}
                      placeholder=""
                      onChange={(e) => onManureDataChange('method', e)}
                    />
                  </div>
                  <div>
                    <Field
                      name="storage"
                      controlId="storage"
                      label={lblPage.labels.manureStorage}
                      styleName="select-box-common"
                      component={SelectControl}
                      options={getDropDownValues(manureNames)}
                      placeholder=""
                      onChange={(e) => onManureDataChange('storage', e)}
                    />
                  </div>
                  <div>&nbsp;</div>
                </div>
              </div>
              <div>&nbsp;</div>
            </div>
          </div>
        </div>
        <div className="manure-storage-data">
          <div>
            <p className="manure-heading">{disableGrain === false ? seasons.seasonsList[0].season : intl.formatMessage({ id: 'GRAIN_METHOD_FERTILIZATION' })}:</p>
          </div>
          <div className="full-width manure-flex">
            <div className="full-width manure-inline-flex">
              <div className="div-width">
                <div className="full-width manure-method-data-value">
                  <div>
                    <Field
                      name="spring_manureMethod"
                      controlId="spring_manureMethod"
                      label={lblPage.labels.method}
                      disabled={false}
                      styleName="select-box-common"
                      component={SelectControl}
                      options={getDropDownMethod(fieldMethod)}
                      placeholder=""
                    />
                  </div>
                  <div>
                    <Field
                      name="spring_manureStorage"
                      controlId="spring_manureStorage"
                      label={lblPage.labels.manureStorage}
                      disabled={false}
                      styleName="select-box-common"
                      component={SelectControl}
                      options={getDropDownValues(manureNames)}
                      placeholder=""
                    />
                  </div>
                  <div>
                    <Field
                      name="spring_manureAmount"
                      label={lblPage.labels.manureAmount}
                      component={TextBoxControl}
                      controlId="spring_manureAmount"
                      units="m^3/daa"
                      type="text"
                      disableValid
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="yieldPaddingTopMin">
                  <div className="button-style-cutdate">
                    <span className="yield-calculation-button-border" onClick={() => resetSeason(1)}>
                      {intl.formatMessage({ id: 'FILTER_RESET' })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {disableGrain === false  && harvestValue >= 1 &&     
        <div className="manure-storage-data">
          <div>
            <p className="manure-heading">{seasons.seasonsList[1].season}:</p>
          </div>
          <div className="full-width manure-flex">
            <div className="full-width manure-inline-flex">
              <div className="div-width">
                <div className="full-width manure-method-data-value">
                  <div>
                    <Field
                      name="summer_manureMethod"
                      controlId="summer_manureMethod"
                      label={lblPage.labels.method}
                      disabled={false}
                      styleName="select-box-common"
                      component={SelectControl}
                      options={getDropDownMethod(fieldMethod)}
                      placeholder=""
                    />
                  </div>
                  <div>
                    <Field
                      name="summer_manureStorage"
                      controlId="summer_manureStorage"
                      label={lblPage.labels.manureStorage}
                      disabled={false}
                      styleName="select-box-common"
                      component={SelectControl}
                      options={getDropDownValues(manureNames)}
                      placeholder=""
                    />
                  </div>
                  <div>
                    <Field
                      name="summer_manureAmount"
                      label={lblPage.labels.manureAmount}
                      component={TextBoxControl}
                      controlId="summer_manureAmount"
                      units="m^3/daa"
                      type="text"
                      disableValid
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="yieldPaddingTopMin">
                  <div className="button-style-cutdate">
                    <span className="yield-calculation-button-border" onClick={() => resetSeason(2)}>
                      {intl.formatMessage({ id: 'FILTER_RESET' })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
        {disableGrain === false  && harvestValue >= 2 &&
        <div className="manure-storage-data">
          <div>
            <p className="manure-heading">{seasons.seasonsList[2].season}:</p>
          </div>
          <div className="full-width manure-flex">
            <div className="full-width manure-inline-flex">
              <div className="div-width">
                <div className="full-width manure-method-data-value">
                  <div>
                    <Field
                      name="fall_manureMethod"
                      controlId="fall_manureMethod"
                      label={lblPage.labels.method}
                      disabled={false}
                      styleName="select-box-common"
                      component={SelectControl}
                      options={getDropDownMethod(fieldMethod)}
                      placeholder=""
                    />
                  </div>
                  <div>
                    <Field
                      name="fall_manureStorage"
                      controlId="fall_manureStorage"
                      label={lblPage.labels.manureStorage}
                      disabled={false}
                      styleName="select-box-common"
                      component={SelectControl}
                      options={getDropDownValues(manureNames)}
                      placeholder=""
                    />
                  </div>
                  <div>
                    <Field
                      name="fall_manureAmount"
                      label={lblPage.labels.manureAmount}
                      component={TextBoxControl}
                      controlId="fall_manureAmount"
                      units="m^3/daa"
                      type="text"
                      disableValid
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="yieldPaddingTopMin">
                  <div className="button-style-cutdate">
                    <span className="yield-calculation-button-border" onClick={() => resetSeason(3)}>
                      {intl.formatMessage({ id: 'FILTER_RESET' })}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </div>
  </div>
</div>
);

export default ManureData;