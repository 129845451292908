import React, { useState } from "react";
import { Typography, Box, Chip, Dialog } from '@mui/material';
import * as taskTemplateStyle from 'mui-components/kart/fieldmap/tasktemplates/TaskTemplateStyle';
import AddVehicleModal from 'mui-components/kart/fieldmap/vehicleModals/AddVehicleModal';
import AddEquipmentModal from 'mui-components/kart/fieldmap/vehicleModals/AddEquipmentModal';

const AddTaskTemplate3 = (props) => {
  const {intl, selectedFields, selectedActivity, taskDetails, closeNewTask } = props;
  const [isModalOpen, setModalOpen] = useState(false);
  const [showAddVehicleDialog, setShowAddVehicleDialog] = useState(false);
  const [showAddEquipmentDialog, setShowAddEquipmentDialog] = useState(false);

  const handleCancel = () => {
    handleCancelButton();
  };

  const handleCancelButton = () => {
    setModalOpen(false);
    closeNewTask();
  };

  const onCloseVehicleDialog = () => {
    setShowAddVehicleDialog(false);
  }

  const onCloseEquipmentDialog = () => {
    setShowAddEquipmentDialog(false);
  }

  return (
    <Box sx={{
      padding: '0px 24px 0px 24px',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '10px',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#A8A8A8',
        borderRadius: '4px',
        transition: 'background-color 0.3s ease',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#B8B8B8',
      }}}
    >
      <Box style={taskTemplateStyle.headerSticky}>
        <Box style={taskTemplateStyle.modalDialogHeader}>
          <Typography style={taskTemplateStyle.modalDialogTitle}>{selectedActivity?.activityName}</Typography>
          {/*<Box sx={{ paddingRight: '10px' }}>
            <MuiChipDropdown
              defaultLabel={intl.formatMessage({ id: 'FIRST_HARVEST_YIELD' })}
              defaultValue={1}
              options={harvestOptions}
              optionStyle={taskTemplateStyle.menuOptionText}
              dropdownStyle={taskTemplateStyle.chipDropdownStyle}
              fieldName='cut'
              onChange={handleFieldChange}
            />
          </Box>*/}
        </Box>
        <Box style={taskTemplateStyle.modalDialogSubHeader}>
          <Typography style={taskTemplateStyle.modalDialogSubTitle}>{selectedFields[0]?.fieldName}</Typography>
        </Box>
      </Box>
      <Box style={taskTemplateStyle.buttonBox}>
        <Box style={taskTemplateStyle.buttonBoxBottom}>
          <Chip
            label={intl.formatMessage({ id: 'cancel' })}
            clickable
            style={taskTemplateStyle.cancelButtonStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            onClick={handleCancel}
          />
          <Chip
            label={intl.formatMessage({ id: 'SAVE' })}
            clickable
            style={taskTemplateStyle.okButtonStyle}
            sx={{ backgroundColor: 'transparent',
              '&:hover, &:focus': {
                backgroundColor: 'transparent',
              }
            }}
            disableRipple
            disabled
          />
        </Box>
      </Box>
      {/* Modal */}
      <Box>    
        <Dialog onClose={() => {}} open={isModalOpen} PaperProps={{
          style: {
            backgroundColor: '#E6E9E8',
            borderRadius: "28px",
            width: "312px",
            minWidth: "280px",
            maxWidth: "312px",
            maxHeight: '30vh'
          }}}>
          <Box style={taskTemplateStyle.cancelModalBox}>
            <Typography style={taskTemplateStyle.cancelModalTitle}>{intl.formatMessage({ id: 'TASK_CANCEL_MODAL_TITLE' })}</Typography>
            <Typography style={taskTemplateStyle.cancelModalSubTitle}>{intl.formatMessage({ id: 'TASK_CANCEL_MODAL_SUB_TITLE' })}</Typography>
          </Box>
          <Box style={taskTemplateStyle.cancelModalBoxButton}>
            <Chip
              label={intl.formatMessage({ id: 'cancel' })}
              clickable
              style={taskTemplateStyle.cancelButtonStyle}
              sx={{ backgroundColor: 'transparent',
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                }
              }}
              disableRipple
              onClick={handleCancelButton}
            />
            <Chip
              label={intl.formatMessage({ id: 'TASK_CONTINUE_REGISTRATION' })}
              clickable
              style={taskTemplateStyle.cancelButtonStyle}
              sx={{ backgroundColor: 'transparent',
                '&:hover, &:focus': {
                  backgroundColor: 'transparent',
                }
              }}
              disableRipple
            />
          </Box>
        </Dialog>
      </Box>
      <AddVehicleModal
        intl={intl}
        vehicleSelectedId={'new'}
        onCloseVehicleDialog={onCloseVehicleDialog}
        showAddVehicleDialog={showAddVehicleDialog}
        assetType={'vehicle'}
        taskDetails={taskDetails}
      />
      <AddEquipmentModal
        intl={intl}
        equipmentSelectedId={'new'}
        onCloseEquipmentDialog={onCloseEquipmentDialog}
        showAddEquipmentDialog={showAddEquipmentDialog}
        assetType={'equipment'}
        taskDetails={taskDetails}
      />
    </Box>
  );
};

export default AddTaskTemplate3;