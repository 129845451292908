import React, { useState } from "react";
import { Box, Chip, Dialog, Slide, List, ListItem, ListItemButton, Typography, Backdrop, ListItemIcon } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from "@mui/icons-material/Check";

const MuiChipMobileDropdown = (props) => {
  const {
    defaultLabel,
    textLabel,
    defaultValue,
    options,
    optionStyle,
    dropdownStyle,
    fieldName,
    onChange
  } = props;

  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({ label: defaultLabel, value: defaultValue });

  const handleChipClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOptionSelect = (label, value) => {
    const newSelectedOption = { label, value };
    setSelectedOption(newSelectedOption);
    if (onChange) {
      onChange(fieldName, value);
    }
    handleClose();
  };

  return (
    <Box>
      <Chip
        label={
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }} style={optionStyle}>
            {selectedOption.label}
            <ArrowDropDownIcon />
          </Box>
        }
        onClick={handleChipClick}
        variant="outlined"
        sx={{
          cursor: "pointer",
          "& .MuiChip-label": {
            padding: "0px",
          },
        }}
        style={dropdownStyle}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "up" }}
        PaperProps={{
          sx: {
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            maxHeight: "50%", // Adjust height as needed
            backgroundColor: '#E6E9E8',
            borderTopLeftRadius: 28,
            borderTopRightRadius: 28,
            transition: "transform 0.3s ease-in-out",
            margin: '0px'
          },
        }}
        BackdropComponent={Backdrop}
        BackdropProps={{
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.4)", // Adjust opacity
            transition: "opacity 0.3s ease-in-out",
          },
        }}
      >
        <Box
          sx={{
            padding: '35px 20px 16px 20px',
            textAlign: 'left'
          }}
        >
          <Typography
            sx={{
              color: '#191C1C',
              fontFamily: 'Inter',
              fontSize: '22px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '28px'
            }}>{textLabel}</Typography>
        </Box>
        <List>
          {options && options.map((option) => (
            <ListItem disablePadding key={option.value}>
              <ListItemButton
                onClick={() => handleOptionSelect(option.label, option.value)}
                sx={{
                  backgroundColor: selectedOption.value === option.value ? '#D8DADB' : '',
                  "&:hover": {
                    backgroundColor: '#D8DADB',
                  },
                  textAlign: 'center',
                  padding: '12px 24px 12px 18px'
                }}
                style={optionStyle}
              >
                <Typography
                  sx={{
                    flexGrow: 1,
                    textAlign: 'left'
                  }}
                >
                  {option.label}
                </Typography>
                {selectedOption.value === option.value && (
                  <ListItemIcon sx={{ minWidth: 'auto', color: '#3F4949' }}>
                    <CheckIcon />
                  </ListItemIcon>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Dialog>
    </Box>
  );
};

export default MuiChipMobileDropdown;
