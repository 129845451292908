import React, { useState } from "react";
import DatePicker from "react-datepicker";
import TodayIcon from '@mui/icons-material/Today';
import { TextField, InputAdornment } from '@mui/material';

const MuiDatePicker = (props) => {
  const {defaultLabel, defaultValue, dateFormat, fieldName, onChange} = props;
  const [selectedDate, setSelectedDate] = useState(defaultValue || new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (onChange) {
      onChange(fieldName, date);
    }
  };

  return (
    <div className="customDatePickerWrapper" style={{width: '100%'}}>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        customInput={
          <TextField
            variant="outlined"
            fullWidth
            label={defaultLabel}
            sx={{
              '& label': {
                color: '#3F4949',
                fontFamily: 'Inter'
              },
              '& label.Mui-focused': {
                color: '#00696D',
                fontFamily: 'Inter'
              }
            }}
            InputProps={{
              style: {
                height: "56px",
                paddingRight: 0,
              },
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{
                    margin: 0,
                  }}
                >
                  <TodayIcon sx={{ marginRight: '8px', color: '#3F4949' }} />
                </InputAdornment>
              ),
            }}
          />
        }
        dateFormat={dateFormat}
      />
    </div>
  );
};

export default MuiDatePicker;