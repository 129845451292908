import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import PrivateRoute from 'components/routes/PrivateRoute';
import CreateTask from 'containers/tasks/tasklist/CreateTask';
import ManageFields from 'mui-components/fields/fieldtab/ManageFields';
import EditField from 'mui-components/fields/fieldtab/EditFieldIndex';
import AddUpdateSoilSample from 'mui-components/fields/fieldtab/soilsamples/AddUpdateSoilSample';
import Settings from 'mui-components/settings';
import Stats from 'mui-components/stats';
import ManureForm from 'mui-components/storages/manurestorage/ManureForm';
import BaleStorageForm from 'mui-components/storages/balestorage/balestorage/BaleStorageForm';
import SelectedBaleList from 'mui-components/storages/balestorage/SelectedBaleList';
import Storage from 'mui-components/storages';
import GrassAnalysisSearch from 'mui-components/grassanalysis';
import GrassAnalysis from 'mui-components/grassanalysis';
import ManageFieldsMobile from 'mui-mobile-components/fields/fieldtab/ManageFields';
import EditFieldMobile from 'mui-mobile-components/fields/fieldtab/EditFieldIndex';
import AddUpdateSoilSampleMobile from 'mui-mobile-components/fields/fieldtab/soilsamples/AddUpdateSoilSample';
import SettingsMobile from 'mui-mobile-components/settings';
import StatsMobile from 'mui-mobile-components/stats';
import ManureFormMobile from 'mui-mobile-components/storages/manurestorage/ManureForm';
import BaleStorageFormMobile from 'mui-mobile-components/storages/balestorage/balestorage/BaleStorageForm';
import SelectedBaleListMobile from 'mui-mobile-components/storages/balestorage/SelectedBaleList';
import StorageMobile from 'mui-mobile-components/storages';
import GrassAnalysisSearchMobile from 'mui-mobile-components/grassanalysis';
import GrassAnalysisMobile from 'mui-mobile-components/grassanalysis';
import ProfileSetting from 'containers/profile/ProfileSettings';
import UpdatePassword from 'containers/profile/UpdatePassword';
import PageNotFound from 'components/PageNotFound';
import Tasks from 'containers/tasks';
import Datavalidation from 'containers/datavalidation/Home';
import Login from 'containers/login/Login';
import FertilizationPlanner from 'containers/fertilization';
import FertilizerPlannerView from 'containers/fertilization/planner/FertilizerPlannerView';
import ReactGraph from 'containers/reactgraph';
import ApexChart from 'containers/apexchart';
import Redirect from 'components/Redirect';
import SelectedBaleStorage from 'mui-components/storages/balestorage';
import SelectedBaleStorageMobile from 'mui-mobile-components/storages/balestorage';
import FieldMapMobile from 'mui-mobile-components/kart/fieldmap';
import FieldMap from 'mui-components/kart/fieldmap';

export default () => (
  <Switch>
    <Route path="/redirect" component={Redirect} />
    <PrivateRoute exact path="/stats" component={isMobile ? StatsMobile : Stats} />
    <PrivateRoute exact path="/soilsamplesimport/:date/:year" component={isMobile ? ManageFieldsMobile : ManageFields} />
    <PrivateRoute exact path="/soilsamplesimport" component={isMobile ? ManageFieldsMobile : ManageFields} />
    <PrivateRoute exact path="/managefields/:id/weather" component={isMobile ? EditFieldMobile : EditField} />
    <PrivateRoute exact path="/managefields/:id/historical" component={isMobile ? EditFieldMobile : EditField} />
    <PrivateRoute exact path="/managefields/:id/manure" component={isMobile ? EditFieldMobile : EditField} />
    <PrivateRoute exact path="/managefields/:id/soilsamples/:soilId" component={isMobile ? AddUpdateSoilSampleMobile : AddUpdateSoilSample} />
    <PrivateRoute exact path="/managefields/:id/soilsamples" component={isMobile ? EditFieldMobile : EditField} />
    <PrivateRoute exact path="/managefields/:id" component={isMobile ? EditFieldMobile : EditField} />
    <PrivateRoute exact path="/managefields" component={isMobile ? ManageFieldsMobile : ManageFields} />
    <PrivateRoute path="/settings" component={isMobile ? SettingsMobile : Settings} />
    <PrivateRoute exact path="/profilesettings" component={ProfileSetting} />
    <PrivateRoute exact path="/profilesettings/Updatepassword" component={UpdatePassword} />
    <PrivateRoute exact path="/datavalidation" component={Datavalidation} />
    <PrivateRoute exact path="/tasks/tasklist/:id/:fieldId" component={CreateTask} />
    <PrivateRoute exact path="/tasks/tasklist/:id" component={CreateTask} />
    <PrivateRoute exact path="/tasks/tasklist/create" component={CreateTask} />
    <PrivateRoute exact path="/task-batch" component={Tasks} />
    <PrivateRoute exact path="/tasks/tasklist" component={Tasks} />
    <PrivateRoute path="/tasks" component={Tasks} />
    <PrivateRoute exact path="/fertilization/manure-storage/:id" component={isMobile ? ManureFormMobile : ManureForm} />
    <PrivateRoute exact path="/fertilization/manure-storage/create" component={isMobile ? ManureFormMobile : ManureForm} />
    <PrivateRoute exact path="/fertilization/planner/:id" component={FertilizerPlannerView} />
    <PrivateRoute exact path="/fertilization/soil-samples" component={FertilizationPlanner} />
    <PrivateRoute exact path="/fertilization" component={isMobile ? ManageFieldsMobile : ManageFields} />
    <PrivateRoute exact path="/storage/bale/:id" component={isMobile ? SelectedBaleListMobile : SelectedBaleList} />
    <PrivateRoute exact path="/storage/manure-storage" component={isMobile ? StorageMobile : Storage} />
    <PrivateRoute exact path="/storage/mineral-storage" component={isMobile ? StorageMobile : Storage} />
    <PrivateRoute exact path="/storage/manure-storage/:id" component={isMobile ? ManureFormMobile : ManureForm} />
    <PrivateRoute exact path="/storage/manure-storage/create" component={isMobile ? ManureFormMobile : ManureForm} />
    <PrivateRoute exact path="/storage/bale-storage/:id" component={isMobile ? BaleStorageFormMobile : BaleStorageForm} />
    <PrivateRoute exact path="/storage/bale-storage/create" component={isMobile ? BaleStorageFormMobile : BaleStorageForm} />
    <PrivateRoute path="/storages" component={isMobile ? StorageMobile : Storage} />
    <PrivateRoute exact path="/storage/harvest/:id" component={isMobile ? SelectedBaleStorageMobile : SelectedBaleStorage} />
    <PrivateRoute path="/graph" component={ReactGraph} />
    <PrivateRoute path="/apexchart" component={ApexChart} />
    <PrivateRoute path="/grassanalysis/search" component={isMobile ? GrassAnalysisSearchMobile : GrassAnalysisSearch} />
    <PrivateRoute path="/grassanalysis" component={isMobile ? GrassAnalysisMobile : GrassAnalysis} />
    <PrivateRoute path="/kart/fieldmap" component={isMobile ? FieldMapMobile : FieldMap} />
    <Route path="/" component={Login} />
    <PrivateRoute component={PageNotFound} />
  </Switch>
);