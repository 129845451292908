import React, { useState } from "react";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { createSvgIcon } from '@mui/material/utils';

const MuiCollapseComponent = ({ title, content }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  const CollapseOpenIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12 15.3746L6 9.37461L7.4 7.97461L12 12.5746L16.6 7.97461L18 9.37461L12 15.3746Z" fill="#1A1C1C"/>
    </svg>,
    'collapseopenicon'
  );

  const CollapseClosedIcon = createSvgIcon(
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g id="Next Icon">
        <path id="icon" d="M9.70492 6L8.29492 7.41L12.8749 12L8.29492 16.59L9.70492 18L15.7049 12L9.70492 6Z" fill="black"/>
      </g>
    </svg>,
    'collapseclosedicon'
  );

  return (
    <Box sx={{width: '100%'}}>
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={handleToggle}
      >
        <Typography
          variant="subtitle1"
          sx={{
            color: 'var(--M3-sys-light-on-surface, #191C1C)',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '24px',
            letterSpacing: '0.5px'
          }}
        >
          {title}
        </Typography>
        <IconButton
          sx={{
            transition: "transform 0.3s ease",
            marginLeft: "auto", // Ensures arrow stays aligned
            padding: "4px 0px 0px 16px",
          }}
          disableRipple
        >
          {isExpanded ? <CollapseOpenIcon/> : <CollapseClosedIcon/>}
        </IconButton>
      </Box>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Box sx={{ padding: "8px 0px" }}>
          {content}
        </Box>
      </Collapse>
    </Box>
  );
};

export default MuiCollapseComponent;
