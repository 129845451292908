import {
  USER_ACTIVITIES_LOADING,
  USER_ACTIVITIES_SUCCESS,
  USER_ACTIVITIES_FAILURE
} from 'constants/store/UserActivities';

const initialState = {
  hasError: false,
  isLoading: false,
  errorMessage: null,
  userActivitiesList: null
};

export default (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case USER_ACTIVITIES_LOADING:
      return {
        ...state,
        isLoading: true,
        hasError: false,
        errorMessage: null
      };
    case USER_ACTIVITIES_FAILURE:
      return {
        ...state,
        hasError: true,
        errorMessage: action.payload,
        isLoading: false
      };
    case USER_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        hasError: false,
        userActivitiesList: action.payload
      };
    default:
      return state;
  }
}