import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import _ from 'lodash';
import ErrorText from '@tine/lib-frontend-components/components/errors/error-text';
import * as assetAction from 'actions/Assets';
import { VEHICLE, EQUIPMENT, VEHICLE_CATEGORY, EQUIPMENT_CATEGORY } from 'constants/index';
import { ADD_VEHICLE_CREATED, ADD_EQUIPMENT_CREATED } from 'constants/MixPanel';
import mixpanel from 'mixpanel-browser';
import { Dialog, DialogTitle, Chip, Box, Stack, Grid } from '@mui/material';
import MuiTextBox from 'components/mui-forms/MuiTextBox';
import MuiCreatableSelectBox from 'components/mui-forms/MuiCreatableSelectBox';
import { buttonStyle } from 'mui-components/fields/fertilization/services/fertilizationStyles';
import * as taskActions from 'actions/Tasks';

class AddVehicleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleType: 'type1',
      vehicleBrand: '',
      vehicleModal: '',
      vehicleYear: '',
      yearHelperText: '',
      intializeCalled: false
    };
    this.handleBrandInputChange = this.handleBrandInputChange.bind(this);
    this.handleBrandChange = this.handleBrandChange.bind(this);
    this.handleModalInputChange = this.handleModalInputChange.bind(this);
    this.handleModalChange = this.handleModalChange.bind(this);
    this.handleYearInputChange = this.handleYearInputChange.bind(this);
    this.handleYearChange = this.handleYearChange.bind(this);
  }

  componentDidMount() {
    const { getAssetTypes, assetType, activeFarm, getAssetsList } = this.props;
    if (activeFarm) {
      getAssetsList(VEHICLE, activeFarm.farm.id);
      getAssetTypes(assetType, activeFarm.farm.id);
      this.initializeValues();
    }
  }

  componentDidUpdate(prevProps) {
    const { assetsDetails, activeFarm, vehicleSelectedId, getAssetsList, onCloseVehicleDialog, taskDetails } = this.props;
    if (!this.state.intializeCalled && vehicleSelectedId !== 'new') {
      this.initializeValues();
    }
    if(assetsDetails.isAssetSuccess && !assetsDetails.isAssetListSuccess) {
      onCloseVehicleDialog();
      this.setState({
        vehicleBrand: '',
        vehicleModal: '',
        vehicleYear: '',
        yearHelperText: '',
        intializeCalled: false
      });
      getAssetsList(VEHICLE, activeFarm.farm.id);
      assetsDetails.isAssetSuccess = false;
    }
    if(taskDetails.createAssetEntityIsSuccess && !this.state.componentDidUpdateState) {
      onCloseVehicleDialog();
      this.setState({
        vehicleBrand: '',
        vehicleModal: '',
        vehicleYear: '',
        yearHelperText: '',
        intializeCalled: false
      });
      getAssetsList(VEHICLE, activeFarm.farm.id);
    }
  }

  initializeValues = () => {
    const { getAssetEntityDetails, activeFarm, vehicleSelectedId } = this.props;
    const selectedAsset = this.getSelectedAsset();
    if (vehicleSelectedId !== 'new' && selectedAsset) {
      this.setState({
        vehicleBrand: selectedAsset.asset.brand,
        vehicleModal: selectedAsset.asset.model,
        vehicleYear: selectedAsset.asset.year > 0 ? (selectedAsset.asset.year).toString() : '',
        yearHelperText: ''
      });
      getAssetEntityDetails(selectedAsset.asset.type.typeId, activeFarm.farm.id);
    } else {
      this.setState({
        vehicleBrand: '',
        vehicleModal: '',
        vehicleYear: '',
        yearHelperText: ''
      });
      getAssetEntityDetails('type1', activeFarm.farm.id);
    }
    if (!this.state.intializeCalled && vehicleSelectedId !== 'new') {
      this.setState({ intializeCalled: true });
    }
  };

  handleBrandInputChange(event, newInputValue) {
    this.setState({
      vehicleBrand: newInputValue
    });
    this.forceUpdate();
  }

  handleBrandChange(event, newValue) {
    if (newValue && !this.getBrandOptions().map(option => option.label.toLowerCase()).includes(newValue.label.toLowerCase())) {
      this.setState({ vehicleBrand: newValue.value });
    } else {
      if(newValue) {
        this.setState({ vehicleBrand: newValue.value });
      }
      this.forceUpdate();
      this.setState({ 
        vehicleModal: '',
        vehicleYear: '',
        yearHelperText: ''
      });
    }
  }

  handleModalInputChange(event, newInputValue) {
    this.setState({
      vehicleModal: newInputValue
    });
    this.forceUpdate();
  }

  handleModalChange(event, newValue) {
    if (newValue && !this.getModalOptions().map(option => option.label.toLowerCase()).includes(newValue.label.toLowerCase())) {
      this.setState({ vehicleModal: newValue.value });
    } else {
      if(newValue) {
        this.setState({ vehicleModal: newValue.value });
      }
      this.forceUpdate();
      this.setState({
        vehicleYear: '',
        yearHelperText: ''
      });
    }
  }

  handleYearInputChange(event, newInputValue) {
    this.setState({
      vehicleYear: newInputValue
    });
    this.forceUpdate();
  }

  handleYearChange(event, newValue) {
    if (newValue && !this.getYearOptions().map(option => option.label) === (newValue.label)) {
      this.setState({ vehicleYear: (newValue.value).toString() });
    } else {
      if(newValue) {
        this.setState({ vehicleYear: (newValue.value).toString() });
      }
      this.forceUpdate();
    }
    this.setState({ yearHelperText: '' });
  }

  // filtering the active asset
  getSelectedAsset = () => {
    const { assetsDetails, assetType, vehicleSelectedId } = this.props;
    if (assetsDetails.assets[assetType]?.length > 0) {
      return assetsDetails.assets[assetType].filter(item => item.assetAssignmentId === vehicleSelectedId)[0];
    }
    return null;
  };

  // To get brand options
  getBrandOptions = () => {
    const { assetsDetails } = this.props;
    if (assetsDetails.typeEntity) {
      const filterUnique = _.orderBy(
        _.uniqBy(assetsDetails.typeEntity, 'brand'),
        [data => data.brand.toLowerCase()],
        ['asc']
      );
      return filterUnique.map(data => ({ value: data.brand, label: data.brand }));
    }
    return [];
  };

  // change handler for brand
  onBrandChange = (e) => {
    const { change } = this.props;
    change('model', '');
    change('year', '');
  };

  /**
   * get Modal options
   * @returns {Array}
   */
  getModalOptions = () => {
    const { assetsDetails } = this.props;
    if (assetsDetails.typeEntity && this.state.vehicleBrand) {
      const models = assetsDetails.typeEntity.filter(data => data.brand === this.state.vehicleBrand);
      return models && models.length >= 0
        ? _.orderBy(
            _.uniqBy(models, 'model').map(data => ({
              value: data.model,
              label: data.model
            })),
            [data => data.label.toLowerCase()],
            ['asc']
          )
        : [];
    }
    return [];
  };

  // change handler for brand
  onModalChange = () => {
    const { change } = this.props;
    change('year', '');
  };

  /**
   * get year options
   * @returns {Array}
   */
  getYearOptions = () => {
    const { assetsDetails } = this.props;
    const { vehicleBrand, vehicleModal } = this.state;
    if (assetsDetails.typeEntity && vehicleModal && vehicleBrand) {
      const years = assetsDetails.typeEntity.filter(
        data => data.model === vehicleModal && data.brand === vehicleBrand
      );
      return years.length >= 0
        ? _.orderBy(
            _.uniqBy(years, 'year').map(data => ({
              value: data.year,
              label: data.year
            })),
            [data => data.label],
            ['asc']
          )
        : [];
    }
    return [];
  };

  getErrorMessage = () => {
    const {
      assetsDetails: { errorMessage }
    } = this.props;
    if (errorMessage.data?.message) {
      if (errorMessage.data.message.includes('Master Data already exists')) {
        return <ErrorText> Master Data already exists for the brand, model and year</ErrorText>;
      }
      if (errorMessage.data.message.includes('You are trying to edit global')) {
        return <ErrorText>You are trying to edit global data which is not permitted</ErrorText>;
      }
    }
    return null;
  };

  createOrUpdateAsset = () => {
    const { assetType, createAssetEntity, updateAsset, activeFarm, userDetails, vehicleSelectedId, intl } = this.props;
    let helperText = false;
    let assetObj;
    if (this.state.vehicleYear) {
      const year = this.state.vehicleYear.toString();
      if (year.length !== 4 || !(new Date(year).getFullYear() <= new Date().getFullYear())) {
        this.setState({ yearHelperText: <div style={{ color: '#cc0000' }}>{intl.formatMessage({ id: 'INVALID_YEAR' })}</div> });
        helperText = true;
      }
      if (this.state.vehicleYear < 1950) {
        this.setState({ yearHelperText: <div style={{ color: '#cc0000' }}>{intl.formatMessage({ id: 'ASSET_YEAR_VALID' })}</div> });
        helperText = true;
      }
    }
    if(!helperText){
      if (vehicleSelectedId !== 'new') {
        this.setState({ yearHelperText: '' });
        const selectedAsset = this.getSelectedAsset();
        assetObj = {};
        if (selectedAsset.asset) {
          assetObj.assetId = selectedAsset.asset.assetId;
          assetObj.year = parseInt(this.state.vehicleYear) || 0;
          assetObj.typeId = selectedAsset.asset.type.typeId;
          assetObj.typeName = selectedAsset.asset.type.typeName;
          assetObj.model = this.state.vehicleModal;
          assetObj.brand = this.state.vehicleBrand;
          assetObj.categoryId = selectedAsset.asset.type.category.categoryId;
          updateAsset(assetObj, activeFarm.farm.id);
        } else {
          const { onCancle } = this.props;
          onCancle();
        }
      } else {
        assetObj = {
          assetId: '',
          type: {
            typeId: 'type1',
            category: {
              categoryId: assetType === VEHICLE ? VEHICLE_CATEGORY : EQUIPMENT_CATEGORY,
              categoryName: assetType === VEHICLE ? VEHICLE : EQUIPMENT
            },
            typeName: 'Tractor'
          },
          brand: this.state.vehicleBrand || '',
          model: this.state.vehicleModal || '',
          year: parseInt(this.state.vehicleYear) || 0
        };
        if(assetType === VEHICLE) {
          mixpanel.identify(userDetails.data.id);
          mixpanel.track(ADD_VEHICLE_CREATED);
        } else {
          mixpanel.identify(userDetails.data.id);
          mixpanel.track(ADD_EQUIPMENT_CREATED);
        }
        createAssetEntity(assetObj, activeFarm.farm.id, userDetails.data.idToken, 'create');
        //createAsset(assetObj, activeFarm.farm.id);
      }
    }
  };

  closePopupWindow = () => {
    const { onCloseVehicleDialog, assetsDetails, getAssetsList, activeFarm } = this.props;
    this.setState({
      vehicleBrand: '',
      vehicleModal: '',
      vehicleYear: '',
      yearHelperText: '',
      intializeCalled: false
    });
    onCloseVehicleDialog();
    if(assetsDetails.hasAssetError) {
      getAssetsList(VEHICLE, activeFarm.farm.id);  
    }
  }

  render() {
    const {
      intl,
      assetsDetails,
      showAddVehicleDialog
    } = this.props;
    const dialogTitleStyle = { fontFamily: 'Inter', fontWeight: 400, fontSize: "24px", fontStyle: "normal", padding: "25px 30px 20px 30px", textAlign: "left", color: "#191C1C" }
    const dialogSubTitleStyle = { ...dialogTitleStyle, fontSize: "14px", fontStyle: "normal", color: "#3F4949", paddingLeft: "30px", paddingRight: "30px", paddingBottom: "20px", paddingTop: "0px"  }
    const inputStyle = { fontFamily: 'Inter', fontWeight: 500, fontSize: "14px", padding: "0px 30px", width: '100%' };
    const scrollStyle = {
      overflowY: 'auto',
      WebkitOverflowScrolling: 'touch',
      scrollbarWidth: 'thin',
      scrollbarColor: '#6F7979 #E6E9E8'
    }
    const { vehicleBrand, vehicleModal, vehicleYear } = this.state;
    return (
      <div>    
        <Dialog onClose={() => {}} open={showAddVehicleDialog} PaperProps={{
          style: {
            backgroundColor: '#E6E9E8',
            borderRadius: "28px",
            width: "397px",
            minWidth: "280px",
            maxWidth: "560px",
          }}}>
          <DialogTitle style={dialogTitleStyle}>{intl.formatMessage({ id: 'VEHICLE' })}</DialogTitle>
          <Box component="div" style={scrollStyle}>
            <Box component="div" style={dialogSubTitleStyle}>
              {intl.formatMessage({ id: 'ADD_VEHICLE_DIALOG' })}
            </Box>
            <div className="manageFarmsLayoutDialogDiv">
              <div><img src="images/CreateVehicle.png" alt="Vehicle" /></div>
              <Box style={inputStyle}>
                <Stack className='text-with-padding'>
                  <MuiTextBox 
                    name="type"
                    id="type"
                    label={intl.formatMessage({ id: 'MACHINE_TYPE' })}
                    variant="outlined"
                    showFullWidth="true"
                    value={intl.formatMessage({ id: 'TRACTOR' })}
                    disabled={true}
                  />
                </Stack>
                <Stack className='text-with-padding'>
                  <MuiCreatableSelectBox 
                    name="brand"
                    id="brand"
                    label={intl.formatMessage({ id: 'MACHINE_BRAND' })}
                    variant="outlined"
                    options={this.getBrandOptions()}
                    inputValue={vehicleBrand} 
                    handleInputChange={this.handleBrandInputChange} 
                    handleChange={this.handleBrandChange} 
                    value={vehicleBrand}
                    labelName={intl.formatMessage({ id: 'create' })}
                  />
                </Stack>
                <Stack className='text-with-padding'>
                  <MuiCreatableSelectBox 
                    name="model"
                    id="model"
                    label={intl.formatMessage({ id: 'MACHINE_MODEL' })}
                    variant="outlined"
                    options={this.getModalOptions()}
                    inputValue={vehicleModal} 
                    handleInputChange={this.handleModalInputChange} 
                    handleChange={this.handleModalChange} 
                    value={vehicleModal}
                    labelName={intl.formatMessage({ id: 'create' })}
                  />
                </Stack>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Stack>
                      <MuiCreatableSelectBox 
                        name="year"
                        id="year"
                        label={intl.formatMessage({ id: 'MACHINE_YEAR' })}
                        variant="outlined"
                        options={this.getYearOptions()}
                        inputValue={vehicleYear} 
                        handleInputChange={this.handleYearInputChange} 
                        handleChange={this.handleYearChange} 
                        value={vehicleYear}
                        labelName={intl.formatMessage({ id: 'create' })}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={6}>&nbsp;</Grid>
                </Grid>
              </Box>
            </div>
            {assetsDetails.hasAssetError ? <div style={{padding: '20px 30px 0px 30px', color: '#cc0000'}}>{this.getErrorMessage()}</div> : ''}
            {this.state.yearHelperText && <div style={{padding: '20px 30px 0px 30px', color: '#cc0000'}}>{this.state.yearHelperText}</div>}
            <div style={{padding: "10px 24px 30px 30px", textAlign: "right"}}>
              <span>
                <Chip
                  label={intl.formatMessage({ id: 'cancel' })}
                  clickable
                  style={buttonStyle}
                  sx={{ backgroundColor: 'transparent',
                    '&:hover, &:focus': {
                      backgroundColor: 'transparent',
                    }
                  }}
                  onClick={this.closePopupWindow}
                  disableRipple
                />
                <Chip
                  label={intl.formatMessage({ id: 'SAVE' })}
                  clickable
                  className={`${(vehicleBrand === '' || vehicleModal === '' || vehicleBrand === null || vehicleModal === null) ? 'disabled' : ''}`}
                  style={buttonStyle}
                  sx={{ backgroundColor: 'transparent',
                    '&:hover, &:focus': {
                      backgroundColor: 'transparent',
                    }
                  }}
                  onClick={() => this.createOrUpdateAsset()}
                  disableRipple
                />
              </span>
            </div>
          </Box>
        </Dialog>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getAssetTypes: (category, FarmId) => dispatch(assetAction.getAssetTypes(category, FarmId)),
  getAssetEntityDetails: (typeId, farmId) => dispatch(assetAction.getAssetEntityDetails(typeId, farmId)),
  createAssetEntity: (data, farmId, token, action) => dispatch(taskActions.createAssetEntity(data, farmId, token, action)),
  updateAsset: (data, farmId) => dispatch(assetAction.updateAsset(data, farmId)),
  getAssetsList: (category, farmId) => dispatch(assetAction.getFarmAssetList(category, farmId))
});

const mapStateToProps = state => ({
  userDetails: state.userDetails,
  activeFarm: state.farmList.activeFarm,
  assetsDetails: state.assetsDetails,
  formValues: getFormValues('AddVehicleModal')(state)
});

export default injectIntl(
  reduxForm({
    form: 'AddVehicleModal'
  })(connect(mapStateToProps, mapDispatchToProps)(AddVehicleModal))
);
