import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { IMG_DOWNLOAD_REQUEST, LOCAL_KEY_USER } from 'constants/index';
import { GetLocalData, GetSessionData } from 'utils';
import NetworkMessage from 'containers/networkstatus/NetworkStatus';
import navToggle from 'actions/NavToggle';
import requestProfileImg from 'actions/ProfilePicture';
import { loginSuccess } from 'actions/Login';
import { taskListPosition } from 'actions/Tasks';
import { isMobile } from 'react-device-detect';

class Header extends Component {
  componentDidMount = () => {
    const {
      AWSLoginDetails,
      userDetails,
      fetchProfileImg,
      profileImage,
      loginSucceeded,
      match,
      taskListPositionInfo
    } = this.props;
    if (!profileImage.data && !profileImage.hasError && AWSLoginDetails && AWSLoginDetails) {
      fetchProfileImg(AWSLoginDetails, IMG_DOWNLOAD_REQUEST, userDetails.id);
    }
    // Get data from local or session
    const user = GetLocalData(LOCAL_KEY_USER) || GetSessionData(LOCAL_KEY_USER);
    if (
      user &&
      user.preferredLanguage &&
      user.preferredLanguage !== userDetails.preferredLanguage
    ) {
      this.updateLang = setTimeout(() => {
        loginSucceeded(user);
      }, 1);
    }
    if (match.path === '/tasks/tasklist/:id' || match.url === '/tasks/tasklist/create') {
      taskListPositionInfo({ fromTask: match.url });
    } else {
      taskListPositionInfo({ fromTask: '' });
    }
  };

  componentDidUpdate = prevProps => {
    const { AWSLoginDetails, userDetails, fetchProfileImg, profileImage } = this.props;
    if (prevProps.AWSLoginDetails !== AWSLoginDetails && !profileImage.data) {
      fetchProfileImg(AWSLoginDetails, IMG_DOWNLOAD_REQUEST, userDetails.id);
    }
  };

  componentWillUnmount() {
    clearInterval(this.updateLang);
  }

  // handling the menu toggle.
  handleMenuToggle = () => {
    const { toggleNav, navBarToggle } = this.props;
    toggleNav(!navBarToggle.isOpen);
  };

  render() {
    const { bcLink, bcTitle, positionCss } = this.props;
    return (
      <>
        <header className={isMobile ? `header ${positionCss}` : `header ${positionCss} borderBottomStyle`}>
          <div className="breadcrumb">
            <p>
              {bcLink ? <Link to={bcLink.to}>{bcLink.text} </Link> : null}
              <span title={bcTitle} className="header-title-name ellipsis-text">
                {bcTitle}
              </span>
              {/* {match.path !== '/managefields/:id' ? (
                <span
                  title={intl.formatMessage({ id: bcTitle })}
                  className="header-title-name ellipsis-text"
                >
                  {intl.formatMessage({ id: bcTitle })}
                </span>
              ) : (
                <span title={bcTitle} className="header-title-name ellipsis-text">
                  {bcTitle}
                </span>
              )} */}
            </p>
          </div>
          {/*<Link to="/profilesettings" onClick={() => {mixpanel.identify(userDetails.id); mixpanel.track(ENTER_PROFILE);}} className={`profile-link ${floatCss}`}>
            <span className="display-name ellipsis-text">
              {userDetails.firstName} {userDetails.lastName}
            </span>
            <ProfileImage
              size="small"
              imgUrl={profileImage.data}
              isLoading={profileImage.isLoading}
            />
          </Link>*/}
        </header>
        <NetworkMessage />
      </>
    );
  }
}

const mapStateToProps = state => ({
  userDetails: state.userDetails.data,
  //AWSLoginDetails: state.AWSLogin.data,
  profileImage: state.profileImage,
  navBarToggle: state.NavToggle,
  hasValidationPermision: state.GpsData
});

const mapDispatchToProps = dispatch => ({
  fetchProfileImg: (awsLogin, type, userId) => dispatch(requestProfileImg(awsLogin, type, userId)),
  toggleNav: bool => dispatch(navToggle(bool)),
  loginSucceeded: data => dispatch(loginSuccess(data)),
  taskListPositionInfo: option => dispatch(taskListPosition(option))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
