import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import * as mapboxStyle from 'mui-mobile-components/kart/mapcomponent/MapboxStyle';
import AddIcon from '@mui/icons-material/Add';
import mixpanel from 'mixpanel-browser';
import { KART_SELECT_FIELD, KART_SELECT_LAST_USED_TASK, KART_SELECT_TASK } from 'constants/MixPanel';
import { SvgIcon } from '@mui/material';
import { createSvgIcon } from '@mui/material/utils';

/* Replace with your own Mapbox access token */
const MAPBOX_ACCESS_TOKEN = 'pk.eyJ1Ijoia291c2hpa3NhaSIsImEiOiJjazBua3FodDAwMG93M2NraTAzNXc3NTd2In0.lhWyF7XvC_bAEW0ZY9KcXQ';

const CustomLocationOffIcon = (props) => (
  <SvgIcon {...props}>
    {/* Outer pin */}
    <path 
      d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z" 
      fill="#75A8AA" // Fill color for the pin
      stroke="#04696D" // Border color for the pin
      strokeWidth="1.5" 
    />
    {/* Inner circle */}
    <circle 
      cx="12" 
      cy="9" 
      r="1.5" 
      fill="#04696D" // Fill color for the circle
      stroke="#04696D" // Border color for the circle
      strokeWidth="1.5" 
    />
  </SvgIcon>
);

const CustomLocationOnIcon = (props) => (
  <SvgIcon {...props}>
    {/* Outer pin */}
    <path 
      d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7z" 
      fill="#04696D" // Fill color for the pin
      stroke="#04696D" // Border color for the pin
      strokeWidth="1.5" 
    />
    {/* Inner circle */}
    <circle 
      cx="12" 
      cy="9" 
      r="1.5" 
      fill="#C3DEE0" // Fill color for the circle
      stroke="#C3DEE0" // Border color for the circle
      strokeWidth="1.5" 
    />
  </SvgIcon>
);

const ArrowIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon">
      <path id="icon_2" d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" fill="#3F4949"/>
    </g>
  </svg>,
  'ArrowIcon',
);

const MapBox = (props) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [clickedArea, setClickedArea] = useState(null);
  const [selectedPolygonId, setSelectedPolygonId] = useState(null);
  const [clickedPolygonName, setClickedPolygonName] = useState(null);
  const [isOpening, setIsOpening] = useState(false);
  const immediateSelectedPolygonId = useRef(null);

  useEffect(() => {
    const { activeFarm, fieldDetails, coordinatesState, userInfo } = props;
    let centerCoordinates = activeFarm.farm.location !== null
      ? [activeFarm.farm.location.longitude, activeFarm.farm.location.lattitude]
      : coordinatesState;

    if (!window.mapboxgl) return;
    window.mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
  
    if (!mapRef.current) {
      mapRef.current = new window.mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mimirovarmesum/cm4u0tu67003f01r9372l6n96',
        center: centerCoordinates,
        zoom: 16,
        attributionControl: false
      });

      const map = mapRef.current;
      let fieldCoordinates = [];
      if (fieldDetails?.data1?.fields) {
        fieldDetails.data1.fields.forEach((data) => {
          let coordinates =
            data.gpsPoints &&
            data.gpsPoints.map((gpsPoint) => [gpsPoint.longitude, gpsPoint.lattitude]);

          if (coordinates.length > 0) {
            fieldCoordinates.push({
              type: "Feature",
              geometry: { type: "Polygon", coordinates: [coordinates] },
              properties: { name: data.fieldName, id: data.fieldId },
            });
          }
        });
      }

      map.on('load', () => {
        const geojsonData = { type: 'FeatureCollection', features: fieldCoordinates };
        map.addSource('fields', { type: 'geojson', data: geojsonData });

        /* Loop through each field and add separate layers for each */
        fieldCoordinates.forEach((polygon) => {
          const fieldName = polygon.properties.name;
          const fieldId = polygon.properties.id;
          const coordinates = polygon.geometry.coordinates[0];
          const center = calculateCenter(coordinates);
          const markerElement = document.createElement('div');
          markerElement.className = `map-marker marker-${fieldId}`;

          const renderMarker = (immediateSelectedPolygonId, fieldId) => {
            ReactDOM.render(
              <Marker
                fieldId={fieldId}
                immediateSelectedPolygonId={immediateSelectedPolygonId}
              />,
              markerElement
            );
          };

          /* Initial render */
          renderMarker(immediateSelectedPolygonId.current, fieldId);

          /* Add the marker to the map at the center of the polygon */
          new window.mapboxgl.Marker(markerElement)
            .setLngLat(center)
            .addTo(map);

          /* Add layer for each polygon */
          map.addLayer({
            id: `field-fill-${fieldId}`,
            type: 'fill',
            source: 'fields',
            paint: { 'fill-color': selectedPolygonId === fieldId ? '#6df6fd' : '#00686b', 'fill-opacity': selectedPolygonId === fieldId ? 0.8 : 0.08 },
            filter: ['==', 'name', fieldName],
          });

          map.addLayer({
            id: `field-outline-${fieldId}`,
            type: 'line',
            source: 'fields',
            paint: { 'line-color': '#00696d', 'line-width': 1 },
            filter: ['==', 'name', fieldName],
          });

          /* Add marker source */
          const markerGeoJSON = {
            type: 'FeatureCollection',
            features: [
              {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: center,
                },
                properties: {
                  title: fieldName,
                },
              },
            ],
          };

          map.addSource(`field-marker-${fieldId}`, {
            type: 'geojson',
            data: markerGeoJSON,
          });          

          map.addLayer({
            id: `field-marker-layer-${fieldId}`,
            type: 'symbol',
            source: `field-marker-${fieldId}`,
            layout: {
              'text-field': [
                'format',
                '   ', // Add padding spaces to create a background effect
                { 'text-color': '#000000' }, // Grey background color
                ['get', 'title'], // Actual text content
                { 'text-color': '#000000' }, // White text color
                '   ' // Closing padding spaces
              ],
              'text-font': ['Inter Regular'],
              'text-size': 18, // Adjust to fit background
              'text-offset': [0, -2.0],
              'text-anchor': 'top',
              'text-padding': 4
            }
          });

          /* Mouse hover effect */
          map.on('mouseenter', `field-fill-${fieldId}`, (e) => {
            if (immediateSelectedPolygonId.current !== fieldId) {
              map.setPaintProperty(`field-fill-${fieldId}`, 'fill-color', '#00686b'); // Highlight color
              map.setPaintProperty(`field-fill-${fieldId}`, 'fill-opacity', 0.3);
              map.getCanvas().style.cursor = 'pointer';
            }
          });

          map.on('mouseleave', `field-fill-${fieldId}`, () => {
            /* Ensure hover effect resets only if it's not the selected polygon */
            if (immediateSelectedPolygonId.current !== fieldId) {
              map.setPaintProperty(`field-fill-${fieldId}`, 'fill-color', '#00686b');
              map.setPaintProperty(`field-fill-${fieldId}`, 'fill-opacity', 0.08);
            }
            map.getCanvas().style.cursor = '';
          });

          /* Click effect */
          map.on('click', `field-fill-${fieldId}`, (e) => {
            if (immediateSelectedPolygonId.current === fieldId) {
              // Reset all polygons to the default color
              fieldCoordinates.forEach((polygon) => {
                const otherFieldId = polygon.properties.id;
                if (otherFieldId !== fieldId) {
                  map.setPaintProperty(`field-fill-${otherFieldId}`, 'fill-color', '#00686b'); // Default color
                  map.setPaintProperty(`field-fill-${otherFieldId}`, 'fill-opacity', 0.08);
                  // Render the default marker for unselected polygons
                  const markerElement = document.querySelector(`.marker-${otherFieldId}`);
                  if (markerElement) {
                    ReactDOM.render(
                      <CustomLocationOffIcon />, // Default marker icon
                      markerElement
                    );
                  }
                }
              });

              // Set the clicked polygon's color
              map.setPaintProperty(`field-fill-${fieldId}`, 'fill-color', '#00686b');
              map.setPaintProperty(`field-fill-${fieldId}`, 'fill-opacity', 0.08);
              immediateSelectedPolygonId.current = null;
              setShowModal(false);
              setSelectedPolygonId(null);
              setClickedPolygonName(null); // Set clicked polygon name
              setClickedArea(null); // Set clicked area name to display in the modal
              const markerElement = document.querySelector(`.marker-${fieldId}`);
              if (markerElement) {
                ReactDOM.render(
                  <CustomLocationOffIcon />, // Default marker icon
                  markerElement
                );
              }
            } else {
              // Reset all polygons to the default color
              fieldCoordinates.forEach((polygon) => {
                const otherFieldId = polygon.properties.id;
                if (otherFieldId !== fieldId) {
                  map.setPaintProperty(`field-fill-${otherFieldId}`, 'fill-color', '#00686b'); // Default color
                  map.setPaintProperty(`field-fill-${otherFieldId}`, 'fill-opacity', 0.08);
                  // Render the default marker for unselected polygons
                  const markerElement = document.querySelector(`.marker-${otherFieldId}`);
                  if (markerElement) {
                    ReactDOM.render(
                      <CustomLocationOffIcon />, // Default marker icon
                      markerElement
                    );
                  }
                }
              });
              mixpanel.identify(userInfo.data.id);
              mixpanel.track(KART_SELECT_FIELD);

              // Set the clicked polygon's color
              map.setPaintProperty(`field-fill-${fieldId}`, 'fill-color', '#6df6fd'); // Selected color
              map.setPaintProperty(`field-fill-${fieldId}`, 'fill-opacity', 0.8);
              setShowModal(true);
              setSelectedPolygonId(fieldId);
              immediateSelectedPolygonId.current = fieldId;
              setClickedPolygonName(fieldName); // Set clicked polygon name
              setClickedArea(fieldName); // Set clicked area name to display in the modal
              // Render the selected marker for the clicked polygon
              const markerElement = document.querySelector(`.marker-${fieldId}`);
              if (markerElement) {
                ReactDOM.render(
                  <CustomLocationOnIcon />, // Selected marker icon
                  markerElement
                );
              }
            }
          });
        });
      });
    }
  }, [props, props.fieldDetails, selectedPolygonId]);
  
  const Marker = ({ fieldId, immediateSelectedPolygonId }) => (
    <>
      {immediateSelectedPolygonId === fieldId ? (
        <CustomLocationOnIcon /> // Selected icon color
      ) : (
        <CustomLocationOffIcon />
      )}
    </>
  );

  /* Function to calculate the center of the polygon by averaging the coordinates */
  const calculateCenter = (coordinates) => {
    let area = 0;
    let centroidX = 0;
    let centroidY = 0;

    // Coordinates are assumed to be an array of [longitude, latitude]
    const n = coordinates.length;

    for (let i = 0; i < n; i++) {
      const [x1, y1] = coordinates[i];
      const [x2, y2] = coordinates[(i + 1) % n]; // Next coordinate, looping back to the first

      // Area calculation (Shoelace formula)
      const a = x1 * y2 - x2 * y1;
      area += a;

      // Centroid calculation (weighted average of coordinates)
      centroidX += (x1 + x2) * a;
      centroidY += (y1 + y2) * a;
    }

    // Final area and centroid coordinates
    area *= 0.5;
    centroidX /= (6 * area);
    centroidY /= (6 * area);

    // Return the centroid as [longitude, latitude]
    return [centroidX, centroidY];
  };

  const forceUpdate = React.useReducer(() => ({}), {})[1];

  /* Close the modal with animation */
  const closeModal = () => {
    const { fieldDetails } = props;
    const map = mapRef.current;
    let fieldCoordinates = [];
    immediateSelectedPolygonId.current = null;
    if (fieldDetails?.data1?.fields) {
      fieldDetails.data1.fields.forEach((data) => {
        let coordinates =
          data.gpsPoints &&
          data.gpsPoints.map((gpsPoint) => [gpsPoint.longitude, gpsPoint.lattitude]);

        if (coordinates.length > 0) {
          fieldCoordinates.push({
            type: "Feature",
            geometry: { type: "Polygon", coordinates: [coordinates] },
            properties: { name: data.fieldName, id: data.fieldId },
          });
        }
      });
    }
    fieldCoordinates && fieldCoordinates.forEach((polygon) => {
      const fieldId = polygon.properties.id;
      map.setPaintProperty(`field-fill-${fieldId}`, 'fill-color', '#00686b'); // Default color
      map.setPaintProperty(`field-fill-${fieldId}`, 'fill-opacity', 0.08);
      const markerElement = document.querySelector(`.marker-${fieldId}`);
      if (markerElement) {
        ReactDOM.render(
          <CustomLocationOffIcon />, // Default marker icon
          markerElement
        );
      }
    });
    setTimeout(() => {
      setShowModal(false);
      setSelectedPolygonId(null);
      setClickedPolygonName(null);
      setClickedArea(null);
      forceUpdate();
    }, 400);
  };

  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        setIsOpening(true);
      }, 400);
    }
  }, [showModal]);  

  const getRecentActivities = () => {
    const { userActivitiesList, openNewTask, userInfo } = props;
    if (userActivitiesList.recent.activities?.length > 0) {
      return (
        userActivitiesList.recent.activities.map((userActivities) => {
          return (
            <Box sx={{ "&:hover": { backgroundColor: "#E6E9E8" }}} style={mapboxStyle.lastUsedBox} onClick={() => {mixpanel.identify(userInfo.data.id); mixpanel.track(KART_SELECT_LAST_USED_TASK); openNewTask(userActivities, selectedPolygonId, clickedPolygonName); }}>
              <Box sx={{ paddingTop: '8px' }}>
                <AddIcon width="24" height="24"/>
              </Box>
              <Box>
                <Typography style={mapboxStyle.lastUsedBoxTitle} key={userActivities.id}>
                  {userActivities.activityName}
                </Typography>
              </Box>
            </Box>
          );
        })
      );
    }
    return null;
  };

  const getCategoryActivities = (category) => {
    const { userActivitiesList, openNewTask, userInfo } = props;
    let categoryActivities = null;
    if(category === 'harvest') { categoryActivities = userActivitiesList.harvest; }
    if(category === 'soil') { categoryActivities = userActivitiesList.soil; }
    if(category === 'fertilization') { categoryActivities = userActivitiesList.fertilization; }
    if(category === 'others') { categoryActivities = userActivitiesList.others; }
    if (categoryActivities.activities?.length > 0) {
      return (
        <Box>
          <Box sx={{ padding: '24px 5px 5px 5px', display: 'flex', justifyContent: 'flex-start' }}>
            <Typography style={mapboxStyle.modalTitleSubHeader}>{categoryActivities.name}</Typography>
          </Box>
          <Box>
            {categoryActivities.activities.map((userActivities) => {
              return (
                <Box sx={{ "&:hover": { backgroundColor: "#E6E9E8" }}} style={mapboxStyle.lastUsedBoxLeft} onClick={() => {mixpanel.identify(userInfo.data.id); mixpanel.track(KART_SELECT_TASK); openNewTask(userActivities, selectedPolygonId, clickedPolygonName);}}>
                  <Box sx={{ paddingTop: '8px' }}>
                    <AddIcon width="24" height="24"/>
                  </Box>
                  <Typography style={mapboxStyle.lastUsedBoxTitle} key={userActivities.id}>{userActivities.activityName}</Typography>
                </Box> )
              })
            }
          </Box>
        </Box>
      );
    }
    return null;
  };

  /* Modal rendering logic */
  const { intl } = props;
  return (
    <Box>
      <div
        ref={mapContainerRef}
        className="map-container-grey"
      />
      {/* Modal that opens from the bottom */}
      {showModal && (
        <>
          {/* Backdrop */}
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 12, // Ensure it's behind the modal
              pointerEvents: 'none', // Prevent clicking on the backdrop
            }}
          />

          {/* Modal */}
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
              height: '50vh',
              background: '#F2F4F4',
              zIndex: 13, // Ensure it's above the backdrop
              transform: isClosing
                ? 'translateY(100%)'
                : isOpening
                ? 'translateY(0)'
                : 'translateY(100%)', // Ensure the modal starts off-screen before opening
              transition: 'transform 0.4s ease-in-out, opacity 0.4s ease-in-out',
              overflowY: 'hidden',
              borderRadius: '28px 28px 0px 0px',
              boxShadow:
                '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.30)',
              opacity: isClosing ? 0 : 1,
            }}
          >
            <Box sx={{ padding: '20px' }}>
              <Box style={mapboxStyle.modalTitle}>
                <Box sx={{ paddingTop: '8px', paddingRight: '12px' }}>
                  <CloseIcon
                    width="24"
                    height="24"
                    onClick={closeModal}
                    style={{ cursor: 'pointer' }}
                  />
                </Box>
                <Typography style={mapboxStyle.modalTitleHeader}>
                  {clickedArea}
                </Typography>
              </Box>
              <Box style={mapboxStyle.lineBorder}>
                <Typography sx={{ width: '100%', height: '1px', background: '#BEC8C9' }}></Typography>
              </Box>
              <Box style={{ overflowY: 'auto', maxHeight: 'calc(44vh - 100px)', marginTop: '20px' }}>
                <Box>
                  <Box sx={{ paddingRight: '8px', display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography style={mapboxStyle.modalTitleSubHeader}>{intl.formatMessage({ id: 'LAST_USED' })}</Typography>
                  </Box>
                  <Box sx={{ width: '100%' }}>
                    {getRecentActivities()}
                    <Box style={mapboxStyle.borderBottomBox}>
                      <Box sx={{borderBottom: '1px solid #BEC8C9', width: '100%'}}></Box>
                    </Box>
                  </Box>
                </Box>
                {getCategoryActivities('harvest')}
                {getCategoryActivities('soil')}
                {getCategoryActivities('fertilization')}
                {getCategoryActivities('others')}
                {/*<Box style={mapboxStyle.toggleSettings}>
                  <Box style={mapboxStyle.toggleLeftHeading}>{intl.formatMessage({ id: 'TOGGLE_SETTINGS' })}</Box>
                  <Box style={mapboxStyle.toggleRightHeading}>
                    <ArrowIcon />
                  </Box>
                </Box>*/}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
export default MapBox;