import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";

const MuiSelectDropdown = (props) => {
  const { defaultLabel, defaultValue, options, fieldName, onChange, required, errorMessage } = props;
  const [value, setValue] = useState(defaultValue || "");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setValue(selectedValue);
    // Reset error if value is selected
    if (required && selectedValue) {
      setError(false);
    }
    if (onChange) {
      onChange(fieldName, selectedValue);
    }
  };

  const handleValidation = () => {
    if (required && !value) {
      setError(true);
    }
  };

  return (
    <FormControl
      fullWidth
      variant="outlined"
      sx={{
        '& label': {
          color: '#3F4949',
          fontFamily: 'Inter'
        },
        '& label.Mui-focused': {
          color: '#00696D',
          fontFamily: 'Inter'
        }
      }}
    >
      <InputLabel>{defaultLabel}</InputLabel>
      <Select
        value={value || ""}
        onChange={handleChange}
        onBlur={handleValidation}
        label={defaultLabel}
      >
        {options && options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{errorMessage || "This field is required"}</FormHelperText>}
    </FormControl>
  );
};

export default MuiSelectDropdown;
