import React from 'react';
import { injectIntl } from 'react-intl';

import Header from 'mui-components/header';
import Fields from 'mui-components/fields/fieldtab/Fields';
import { Box } from '@mui/material';

const ManageFields = ({ intl, location }) => (
  <Box className={`${'main-content'}`}>
    {(location.pathname).includes('managefields') &&
    <Header bcTitle={intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_2' })} />}
    {(location.pathname).includes('fertilization') &&
    <Header bcTitle={intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_3' })} />}
    {(location.pathname).includes('soilsamplesimport') &&
    <Header bcTitle={intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_4' })} />}
    <div className={`${'main-content'}`}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', flexGrow: 1, paddingTop: '0px' }}></Box>
      <Fields />
    </div>
  </Box>
);

export default injectIntl(ManageFields);
