export const getMixPanelId = () => {
  if (window.location.hostname === "localhost") {
      return "6d12a163c85ba161e0411ba1edb4aa04";
  }
  if (window.location.hostname.includes("dev")) {
      return "6d12a163c85ba161e0411ba1edb4aa04"
  }
  return "8a6cf173102151f22af2bba534465cef";
}

export const MIXPANEL_ID = getMixPanelId();
export const WEB = "Web";
export const LOGIN = "Login";
export const REGISTER_USER = "RegisterNewUser";
export const VIEW_PRICE_PLAN = "ViewPriceplan";
export const REGISTER_COMPLETE = "RegisterNewUserComplete";
export const TASK_COMPLETED = "CompleteTask";
export const ENTER_TASKS = "EnterTasksOverview";
export const ENTER_FIELD = "EnterFieldOverview";
export const ENTER_STATS = "EnterStats";
export const ENTER_SETTINGS = "EnterSettings";
export const ENTER_PROFILE = "EnterProfile";
export const ENTER_FP_PAGE = "EnterFPpage";
export const START_NEW_PLAN = "StartNewPlan";
export const CREATE_NEW_PLAN = "CreateNewPlan";
export const SAVE_PLAN = "SavePlan";
export const UPDATE_PLAN = "UpdatePlan";
export const UPDATE_FP_PLANNING = "UpdateFPplanning";
export const UPDATE_FP_RECALCULATE = "UpdateFPrecalculate";
export const EDIT_PLAN = "EditPlan";
export const EXPORT_PLAN = "ExportPlan";
export const VIEW_PLAN = "ViewPlan";
export const CHANGE_MANURE_AMOUNT = "ChangeManureAmount";
export const CHANGE_MINERAL_AMOUNT = "ChangeMineralAmount";
export const CHANGE_MINERAL_TYPE_START = "ChangeMineralTypeStart";
export const CHANGE_MINERAL_TYPE_COMPLETE = "ChangeMineralTypeComplete";
export const DELETE_MINERAL_TYPE_COMPLETE = "DeleteMineralTypeComplete";
export const ENTER_WEATHER_TAB = "EnterWeatherTab";
export const ADD_FIELD = "AddField";
export const FIELD_CREATED = "FieldCreated";
export const EDIT_FIELD = "EditField";
export const FIELD_UPDATED = "FieldUpdated";
export const IMPORT_FIELDS = "ImportFields";
export const EXPORT_FIELDS = "ExportFields";

export const ENTER_BALE_STORAGE = "EnterBaleStorage";
export const ENTER_HARVEST_VIEW = "EnterBaleStorageHarvestview";
export const ENTER_FIELD_VIEW = "EnterBaleStorageFieldview";
export const VIEW_ANALYSIS = "ViewBSanalysis";
export const ADD_BALE = "AddBale";
export const ADD_BALE_FROM_TASK = "AddBaleFromTask";
export const ADD_NEW_BALE = "AddNewBale";
export const ADD_BALE_COMPLETE = "AddBaleComplete";
export const SUB_BALE = "SubtractBale";
export const SUB_BALE_COMPLETE = "SubtractBaleComplete";
export const ADD_BALE_STORAGE = "AddBaleStorage";
export const ADD_BALE_STORAGE_COMPLETE = "AddBaleStorageComplete";
export const ADD_FEED_ANALYSIS = "AddFeedAnalysis";
export const ADD_FEED_ANALYSIS_COMPLETE = "AddFeedAnalysisComplete";

export const ADD_FEED_SCHEDULER = "AddFeedScheduler";
export const FEED_ANALYSIS_CREATED = "FeedSchedulerCreated";
export const FEED_ANALYSIS_UPDATED = "FeedSchedulerUpdated";
export const UPLOAD_SOIL_SAMPLE_RESULTS = "UploadSoilSampleResults";
export const VIEW_SOIL_SAMPLE_RESULTS = "ViewSoilSampleResults";
export const MAP_SOIL_SAMPLE_RESULTS = "MapSoilSampleResults";

export const GRASS_ANALYSIS_COMPLETE = "Tine_surfôr_ordered";
export const VIEW_360 = "View360";
export const CHANGE_MF_FARM = "ChangeMFfarm";
export const CHANGE_MANURE = "ChangeManure";
export const ADD_FARM = "AddFarm";
export const ADD_FARM_COMPLETE = "AddFarmComplete";
export const CANCEL_FARM_SUBSCRIPTION = "CancelFarmSubscription";

export const ADD_MANURE = "AddManure";
export const ADD_MINERAL_FERTILISER = "AddMineralFertiliser"
export const ADD_GRASS_ANALYSIS = "AddGrassAnalysis";

export const ADD_TASK = "AddTask";
export const VIEW_TC = "ViewT&C";
export const ADD_SOIL_SAMPLE = "AddSoilSample";
export const ADD_SOIL_SAMPLE_CREATED = "SoilSampleCreated";

export const ADD_VEHICLE = "AddVehicle";
export const ADD_VEHICLE_CREATED = "VehicleCreated";
export const ADD_EQUIPMENT = "AddEquipment";
export const ADD_EQUIPMENT_CREATED = "EquipmentCreated";

export const CLICK_NITROGEN_BOX = "ClickNitrogenbox";
export const EXPAND_OVER_FERTILIZATION = "ExpandOverFertilization";
export const EXPAND_UNDER_FERTILIZATION = "ExpandUnderFertilization";

export const KART_CLICKED_MAP = "ClickedMap";
export const KART_SELECT_FIELD = "selectField";
export const KART_SELECT_LAST_USED_TASK = "selectLastUsedTask";
export const KART_SELECT_TASK = "selectTask";
export const KART_CANCEL_MAP_TASK = "cancelMapTask";
export const KART_SAVE_MAP_TASK = "saveMapTask";