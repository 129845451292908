export function template1(formData) {
  return {
    farmId: formData.farmId,
    activityId: formData.activityId,
    activityName: formData.activityName,
    fieldIds: formData.fieldIds,
    startTime: formData.startTime,
    notes: formData.note,
    vehicleAssetId: formData.vehicleAssetId,
    equipmentAssetId: formData.equipmentAssetId,
    waterJournal: formData.waterJournal,
    waterJournalAdded: formData.waterJournalAdded,
    taskActivity: {
      activityId: formData.activityId,
      activityName: formData.activityName,
      cut: formData.cut,
      cutAmount: formData.cutAmount,
      cutUnit: formData.cutUnit,
      locationId: formData.locationId,
      ensilationText: formData.ensilationText,
      ensilationAmount: formData.ensilationAmount,
      ensilationUnit: formData.ensilationUnit,
      drymatter: formData.drymatter,
      weight: formData.weight,
    },
    locationId: formData.locationId
  };
}
export function template2(formData) {
  return {
    farmId: formData.farmId,
    activityId: formData.activityId,
    activityName: formData.activityName,
    fieldIds: formData.fieldIds,
    startTime: formData.startTime,
    notes: formData.note,
    vehicleAssetId: formData.vehicleAssetId,
    equipmentAssetId: formData.equipmentAssetId,
    waterJournal: formData.waterJournal,
    waterJournalAdded: formData.waterJournalAdded,
    taskActivity: {
      activityId: formData.activityId,
      activityName: formData.activityName,
      cut: formData.cut,
      cutAmount: formData.cutAmount,
      cutUnit: formData.cutUnit,
      ensilationText: formData.ensilationText,
      ensilationAmount: formData.ensilationAmount,
      ensilationUnit: formData.ensilationUnit,
      drymatter: formData.drymatter,
      weight: formData.weight,
    }
  };
}
export function template3(formData) {
  return {
    farmId: formData.farmId,
    activityId: formData.activityId,
    activityName: formData.activityName,
    fieldIds: formData.fieldIds,
    startTime: formData.startTime,
    notes: formData.note,
    vehicleAssetId: formData.vehicleAssetId,
    equipmentAssetId: formData.equipmentAssetId,
    waterJournal: formData.waterJournal,
    waterJournalAdded: formData.waterJournalAdded,
    taskActivity: {
      activityId: formData.activityId,
      activityName: formData.activityName,
      cut: formData.cut
    }
  };
}
export function template4(formData) {
  return {
    farmId: formData.farmId,
    activityId: formData.activityId,
    activityName: formData.activityName,
    fieldIds: formData.fieldIds,
    startTime: formData.startTime,
    notes: formData.note,
    vehicleAssetId: formData.vehicleAssetId,
    equipmentAssetId: formData.equipmentAssetId,
    waterJournal: formData.waterJournal,
    waterJournalAdded: formData.waterJournalAdded,
    taskActivity: {
      activityId: formData.activityId,
      activityName: formData.activityName,
      totalHarvest: formData.totalHarvest,
      waterPercent: formData.waterPercent
    }
  };
}
export function template5(formData) {
  return {
    farmId: formData.farmId,
    activityId: formData.activityId,
    activityName: formData.activityName,
    fieldIds: formData.fieldIds,
    startTime: formData.startTime,
    notes: formData.note,
    vehicleAssetId: formData.vehicleAssetId,
    equipmentAssetId: formData.equipmentAssetId,
    waterJournal: formData.waterJournal,
    waterJournalAdded: formData.waterJournalAdded,
    taskActivity: {
      activityId: formData.activityId,
      activityName: formData.activityName,
      mixText: formData.mixText,
      amount: formData.amount,
      unit: formData.unit
    }
  };
}
export function template6(formData) {
  return {
    farmId: formData.farmId,
    activityId: formData.activityId,
    activityName: formData.activityName,
    fieldIds: formData.fieldIds,
    startTime: formData.startTime,
    notes: formData.note,
    vehicleAssetId: formData.vehicleAssetId,
    equipmentAssetId: formData.equipmentAssetId,
    waterJournal: formData.waterJournal,
    waterJournalAdded: formData.waterJournalAdded,
    taskActivity: {
      activityId: formData.activityId,
      activityName: formData.activityName,
      comboSowingAmount: formData.comboSowingAmount,
      sowingTypeText: formData.sowingTypeText,
      comboFertiAmount: formData.comboFertiAmount,
      mineralFertiType: formData.mineralFertiType,
      comboSowingUnit: formData.comboSowingUnit,
      comboFertiUnit: formData.comboFertiUnit
    }
  };
}
export function template7(formData) {
  return {
    farmId: formData.farmId,
    activityId: formData.activityId,
    activityName: formData.activityName,
    fieldIds: formData.fieldIds,
    startTime: formData.startTime,
    notes: formData.note,
    vehicleAssetId: formData.vehicleAssetId,
    equipmentAssetId: formData.equipmentAssetId,
    waterJournal: formData.waterJournal,
    waterJournalAdded: formData.waterJournalAdded,
    taskActivity: {
      activityId: formData.activityId,
      activityName: formData.activityName,
      type: formData.type,
      subType: formData.subType,
      amount: formData.amount === "" ? null : formData.amount,
      unit: formData.planUnit,
      planAmount: formData.planAmount === "" ? null : formData.planAmount,
      planUnit: formData.planUnit,
      season: formData.season === "" ? null : formData.season
    }
  };
}
export function template8(formData) {
  return {
    farmId: formData.farmId,
    activityId: formData.activityId,
    activityName: formData.activityName,
    fieldIds: formData.fieldIds,
    startTime: formData.startTime,
    notes: formData.note,
    vehicleAssetId: formData.vehicleAssetId,
    equipmentAssetId: formData.equipmentAssetId,
    waterJournal: formData.waterJournal,
    waterJournalAdded: formData.waterJournalAdded,
    taskActivity: {
      activityId: formData.activityId,
      activityName: formData.activityName
    }
  };
}