import React, { useState, useEffect } from "react";
import { Box, Chip, Menu, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const MuiChipDropdown = (props) => {
  const { defaultLabel, defaultValue, options, optionStyle, dropdownStyle, fieldName, onChange } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState({ label: defaultLabel, value: defaultValue });

  useEffect(() => {
    setSelectedOption({ label: defaultLabel, value: defaultValue });
  }, [defaultLabel, defaultValue]);

  const handleChipClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (label, value) => {
    const newSelectedOption = { label, value };
    setSelectedOption(newSelectedOption);
    if (onChange) {
      onChange(fieldName, value);
    }
    handleClose();
  };

  return (
    <Box>
      <Chip
        label={
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }} style={optionStyle}>
            {selectedOption.label}
            <ArrowDropDownIcon />
          </Box>
        }
        onClick={handleChipClick}
        variant="outlined"
        sx={{
          cursor: "pointer",
          "& .MuiChip-label": {
            padding: "0px",
          },
        }}
        style={dropdownStyle}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        componentsProps={{
          paper: {
            sx: {
              minWidth: 150, // Optional: set a minimum width
              backgroundColor: '#E6E9E8',
            },
          },
        }}
      >
        {options &&
          options.map((option) => (
            <MenuItem
              key={option.label}
              onClick={() => handleMenuItemClick(option.label, option.value)}
              sx={{
                backgroundColor: selectedOption.value === option.value ? '#CCE8E9' : '',
                "&:hover": {
                  backgroundColor: '#CCE8E9',
                },
              }}
              style={optionStyle}
            >
              {option.label}
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};

export default MuiChipDropdown;
