import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { CssGridLayout } from '@tine/lib-frontend-components/components/alignment/css-grid-layout/css-grid-layout';
import SubscriptionCard from 'mui-components/settings/subscription/SubscriptionCard';

class SubscriptionList extends React.Component {
  renderList = () => {
    const { subscriptionList } = this.props;
    if (subscriptionList?.length > 0) {
      return (
        subscriptionList.map((subscription) => {
          return (
            <ul className="field-list-wrapper full-width" key={subscription.id} style={{ paddingLeft: '18px', paddingRight: '16px' }}>
              <SubscriptionCard subscription={subscription} />
            </ul>
          );
        })
      );
    }
    return null;
  };

  render() {
    return (
      <div className="full-width asset-list-container">
        <div className="full-width asset-list-content">
          <div className="vehicle-list-flex-container full-width">
            <CssGridLayout>
              <div className="vehicle-list-details">
                {this.renderList()}
              </div>
            </CssGridLayout>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
});

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  hasSupportAdminRole: state.SupportAdminRole
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SubscriptionList));
