import React, { Component } from 'react';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { getFormValues, Field, reduxForm } from 'redux-form';
import SelectControl from 'components/forms/SelectBoxControl';
import ToggleButton from 'react-toggle-button';
import RadioButton from '@tine/lib-frontend-components/components/inputs/radio-button';
import { withRouter } from 'react-router-dom';
import TextAreaControl from 'components/forms/TextAreaControl';
import * as taskActions from 'actions/Tasks';
import Loader from 'components/Loader';
import { format } from 'date-fns';
import _ from 'lodash';
import { 
  SecondsTohoursMinutes,
  GetDateObj,
  GetValuesFromDate,
  ParseISOString,
  PadString,
  AmountValidation,
  getValueByDateAndTime } from 'utils';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import TextBoxControl from 'components/forms/TextBoxControl';
import isMatch from 'date-fns/isMatch'
import {
  KORN,
  OLJEVEKSTER,
  ERTER,
  POTET,
  JORDBR,
  EPLER,
  IKKEANGITT,
  SPIRING,
  BLADUTVIKLING_HOVEDSKUDD,
  BUSKING_UTVIKLING_AV_SIDESKUDD,
  STREKKING_HOVEDSKUDD,
  AKS_ELLER_TOPPDANNELSE,
  SKYTING_HOVEDSKUDD,
  BLOMSTRING_HOVEDSKUDD,
  FROKJERNE_UTVIKLING,
  MODNING,
  VISNING,
  UGRAS,
  POTETSYKDOMMER,
  SOPP,
  SKADEDYR,
  MANGELSYKDOMMER,
  VEKSTAVSLUTNING,
  VEKSTREGULERING,
  BEISING
} from 'constants/index';
import {
  pesticideTypeOptions,
  pesticideUnitOptions,
  growthStageSubTypeKornOptions,
  growthStageSubTypeOljeveksterOptions,
  growthStageSubTypeErterOptions,
  growthStageSubTypePotetOptions,
  growthStageSubTypeJordbrOptions,
  growthStageSubTypeEplerOptions,
  growthStageSubSubTypeSpiringOptions,
  growthStageSubTypeIkkeAngittOptions,
  growthStageSubSubTypeBladutviklingOptions,
  growthStageSubSubTypeBuskingOptions,
  growthStageSubSubTypeStrekkingOptions,
  growthStageSubSubTypeAksOptions,
  growthStageSubSubTypeSkytingOptions,
  growthStageSubSubTypeBlomstringOptions,
  growthStageSubSubTypeFrkjerneOptions,
  growthStageSubSubTypeModningOptions,
  growthStageSubSubTypeVisningOptions,
  pestUgrasSubTypeOptions,
  pestPotetsykdommerSubTypeOptions,
  pestSoppSubTypeOptions,
  pestSkadedyrSubTypeOptions,
  pestMangelsykdommerSubTypeOptions,
  pestVekstavslutningSubTypeOptions,
  pestVekstreguleringSubTypeOptions,
  pestBeisingSubTypeOptions,
  waterUnitOptions,
  pestOptions,
  growthStageOptions
} from 'utils/DynamicFields';

class TemplatePesticide extends Component {
  constructor(props) {
    super(props);
    const { taskList } = this.props;
    this.state = {
      initializeState: false,
      errorFieldsState: null
    };
    let newState = {};
    taskList.forEach((list, index) => {
      newState[`startDate_${index}`] = null;
      newState[`endDate_${index}`] = null;
      newState[`startTime_${index}`] = null;
      newState[`endTime_${index}`] = null;
      newState[`updateDuration_${index}`] = false;
      newState[`pestSubTypeOptions_${index}`] = [];
      newState[`growthSubTypeOptions_${index}`] = [];
      newState[`growthSubSubTypeOptions_${index}`] = [];
      newState[`waterJournalOn_${index}`] = false;
      newState[`waterEquipmentUsed_${index}`] = true;
      newState[`checkLessThan2PCSlope_${index}`] = false;
      newState[`checkVegetatedBufferExists_${index}`] = false;
      newState[`checkNozzlesProvideTargetMovement_${index}`] = false;
      newState[`checkDamperSetForOptimalDistribution_${index}`] = false;
      newState[`checkOneEndClosedToAvoidSurfaceWater_${index}`] = false;
      newState[`stateFieldName_${index}`] = "";
    });
    this.setState(newState);
  }

  componentDidMount() {
    const { taskList } = this.props;
    taskList.map((list, index) =>
      this.fetchDateTime(list, index)
    );
    this.setState({
      initializeState: true
    });
  }

  componentDidUpdate() {
    const { initializeState } = this.state;
    if(initializeState) {
      this.initializeListValues();
      this.setState({
        initializeState: false
      });
    }
  }

  initializeListValues = () => {
    const { taskList } = this.props;
    taskList.map((list, index) =>
      this.initializeValues(list, index)
    );
  }

  initializeValues = (list, index) => {
    const { change, activitiesCommon } = this.props;
    if (list.fieldId !== null) {
      this.setState({
        [`stateFieldName_${index}`]: list.fieldId
      });
    }
    if (list.vehicleAssetId !== null) {
      let selectedOption = {
        value: list.vehicleAssetId,
        label: `${list.vehicleAssetBrand} ${list.vehicleAssetModel}`
      };
      change(`vehicle_${index}`, selectedOption);
    }
    if (list.equipmentAssetId !== null) {
      let selectedOption = {
        value: list.equipmentAssetId,
        label: `${list.equipmentAssetBrand} ${list.equipmentAssetModel}`
      };
      change(`equipment_${index}`, selectedOption);
    }
    if (list.startTime !== null) {
      change(`startTime_${index}`, format(new Date(ParseISOString(list.startTime)), 'dd.MM.yyyy HH:MM'));
    }
    if (list.endTime !== null) {
      change(`endTime_${index}`, format(new Date(ParseISOString(list.endTime)), 'dd.MM.yyyy HH:MM'));
    }
    if (list.weather !== null) {
      let labelValue = "";
      if(list.weather === 'sunny') {
        labelValue = <FormattedMessage id="SUNNY" />
      } else if(list.weather === 'cloudy') {
        labelValue = <FormattedMessage id="CLOUDY" />
      } else if(list.weather === 'sun_and_rain') {
        labelValue = <FormattedMessage id="SUNANDRAIN" />
      } else if(list.weather === 'overcast') {
        labelValue = <FormattedMessage id="OVERCAST" />
      } else {
        labelValue = <FormattedMessage id="RAIN" />
      }
      let selectedOption = {
        value: list.weather,
        label: labelValue
      };
      change(`weather_${index}`, selectedOption);
    }
    if (list.assigneeId !== null) {
      let selectedOption = {
        value: list.assigneeId,
        label: list.assigneeFirstName+" "+list.assigneeLastName
      };
      change(`assignee_${index}`, selectedOption);
    }
    if (list.notes !== null) {
      change(`notes_${index}`, list.notes);
    }
    if(list.dynamicData !== null) {
      const dynamicDataObj = JSON.parse(list.dynamicData);
      if(dynamicDataObj.pestiside_type && dynamicDataObj.pestiside_type.length > 0) {
        dynamicDataObj.pestiside_type.forEach((type, indexx) => {
          let selectedOption = {
            value: type.name,
            label: type.name
          };
          let selectedUnitOption = {
            value: type.unit,
            label: type.unit
          };
          change(`pesticideName_${index}_${indexx}`, selectedOption);
          change(`pesticideAmount_${index}_${indexx}`, type.amount);
          change(`pesticideUnit_${index}_${indexx}`, selectedUnitOption);
        });
      }
      if(dynamicDataObj.water_amount) {
        change(`waterAmount_${index}`, dynamicDataObj.water_amount);
      }
      if(dynamicDataObj.water_unit) {
        let selectedUnitOption = {
          value: dynamicDataObj.water_unit,
          label: dynamicDataObj.water_unit
        };
        change(`waterUnit_${index}`, selectedUnitOption);
      }
      if(dynamicDataObj.crop) {
        change(`crop_${index}`, dynamicDataObj.crop);
      }
      if(dynamicDataObj.reason) {
        change(`reason_${index}`, dynamicDataObj.reason);
      }
      if(dynamicDataObj.pest_data) {
        let selectedOption = {
          value: dynamicDataObj.pest_data,
          label: dynamicDataObj.pest_data
        };
        change(`pestData_${index}`, selectedOption);
        if (dynamicDataObj.pest_data === UGRAS ) {
          this.setState({
            [`pestSubTypeOptions_${index}`]: pestUgrasSubTypeOptions(activitiesCommon)
          });
        }
        if (dynamicDataObj.pest_data === POTETSYKDOMMER ) {
          this.setState({
            [`pestSubTypeOptions_${index}`]: pestPotetsykdommerSubTypeOptions(activitiesCommon)
          });
        }
        if (dynamicDataObj.pest_data === SOPP ) {
          this.setState({
            [`pestSubTypeOptions_${index}`]: pestSoppSubTypeOptions(activitiesCommon)
          });
        }
        if (dynamicDataObj.pest_data === SKADEDYR ) {
          this.setState({
            [`pestSubTypeOptions_${index}`]: pestSkadedyrSubTypeOptions(activitiesCommon)
          });
        }
        if (dynamicDataObj.pest_data === MANGELSYKDOMMER ) {
          this.setState({
            [`pestSubTypeOptions_${index}`]: pestMangelsykdommerSubTypeOptions(activitiesCommon)
          });
        }
        if (dynamicDataObj.pest_data === VEKSTAVSLUTNING ) {
          this.setState({
            [`pestSubTypeOptions_${index}`]: pestVekstavslutningSubTypeOptions(activitiesCommon)
          });
        }
        if (dynamicDataObj.pest_data === VEKSTREGULERING ) {
          this.setState({
            [`pestSubTypeOptions_${index}`]: pestVekstreguleringSubTypeOptions(activitiesCommon)
          });
        }
        if (dynamicDataObj.pest_data === BEISING ) {
          this.setState({
            [`pestSubTypeOptions_${index}`]: pestBeisingSubTypeOptions(activitiesCommon)
          });
        }
        if(dynamicDataObj.pest_sub_list && dynamicDataObj.pest_sub_list.length > 0) {
          const subPestTypeArray = [];
          dynamicDataObj.pest_sub_list.forEach(list => {
            subPestTypeArray.push({ label: list, value: list });
          });
          if(subPestTypeArray.length > 0) {
            change(`pestSubData_${index}`, subPestTypeArray);
          }
        }
      }
      if(dynamicDataObj.growthStage) {
        let selectedOption = {
          value: dynamicDataObj.growthStage,
          label: dynamicDataObj.growthStage
        };
        change(`growthStage_${index}`, selectedOption);
        if (dynamicDataObj.growthStage === KORN) {
          this.setState({
            [`growthSubTypeOptions_${index}`]: growthStageSubTypeKornOptions(activitiesCommon)
          });
        }
        if (dynamicDataObj.growthStage === OLJEVEKSTER) {
          this.setState({
            [`growthSubTypeOptions_${index}`]: growthStageSubTypeOljeveksterOptions(activitiesCommon)
          });
        }
        if (dynamicDataObj.growthStage === ERTER) {
          this.setState({
            [`growthSubTypeOptions_${index}`]: growthStageSubTypeErterOptions(activitiesCommon)
          });
        }
        if (dynamicDataObj.growthStage === POTET) {
          this.setState({
            [`growthSubTypeOptions_${index}`]: growthStageSubTypePotetOptions(activitiesCommon)
          });
        }
        if (dynamicDataObj.growthStage === JORDBR) {
          this.setState({
            [`growthSubTypeOptions_${index}`]: growthStageSubTypeJordbrOptions(activitiesCommon)
          });
        }
        if (dynamicDataObj.growthStage === EPLER) {
          this.setState({
            [`growthSubTypeOptions_${index}`]: growthStageSubTypeEplerOptions(activitiesCommon)
          });
        }
        if (dynamicDataObj.growthStage === IKKEANGITT) {
          this.setState({
            [`growthSubTypeOptions_${index}`]: growthStageSubTypeIkkeAngittOptions(activitiesCommon)
          });
        }
        if (dynamicDataObj.growthStage === UGRAS ) {
          this.setState({
            [`growthSubTypeOptions_${index}`]: pestUgrasSubTypeOptions(activitiesCommon)
          });
        }
        if(dynamicDataObj.growthStage_sub) {
          let selectedGrowthSubOption = {
            value: dynamicDataObj.growthStage_sub,
            label: dynamicDataObj.growthStage_sub
          };
          change(`growthStageSub_${index}`, selectedGrowthSubOption);
          if (dynamicDataObj.growthStage_sub === SPIRING) {
            this.setState({
              [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeSpiringOptions(activitiesCommon)
            });
          }
          if (dynamicDataObj.growthStage_sub === BLADUTVIKLING_HOVEDSKUDD) {
            this.setState({
              [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeBladutviklingOptions(activitiesCommon)
            });
          }
          if (dynamicDataObj.growthStage_sub === BUSKING_UTVIKLING_AV_SIDESKUDD) {
            this.setState({
              [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeBuskingOptions(activitiesCommon)
            });
          }
          if (dynamicDataObj.growthStage_sub === STREKKING_HOVEDSKUDD) {
            this.setState({
              [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeStrekkingOptions(activitiesCommon)
            });
          }
          if (dynamicDataObj.growthStage_sub === AKS_ELLER_TOPPDANNELSE) {
            this.setState({
              [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeAksOptions(activitiesCommon)
            });
          }
          if (dynamicDataObj.growthStage_sub === SKYTING_HOVEDSKUDD) {
            this.setState({
              [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeSkytingOptions(activitiesCommon)
            });
          }
          if (dynamicDataObj.growthStage_sub === BLOMSTRING_HOVEDSKUDD) {
            this.setState({
              [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeBlomstringOptions(activitiesCommon)
            });
          }
          if (dynamicDataObj.growthStage_sub === FROKJERNE_UTVIKLING) {
            this.setState({
              [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeFrkjerneOptions(activitiesCommon)
            });
          }
          if (dynamicDataObj.growthStage_sub === MODNING) {
            this.setState({
              [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeModningOptions(activitiesCommon)
            });
          }
          if (dynamicDataObj.growthStage_sub === VISNING) {
            this.setState({
              [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeVisningOptions(activitiesCommon)
            });
          }
          if(dynamicDataObj.growthStage_sub_subtype) {
            let selectedGrowthSubSubOption = {
              value: dynamicDataObj.growthStage_sub_subtype,
              label: dynamicDataObj.growthStage_sub_subtype
            };
            change(`growthStageSubSub_${index}`, selectedGrowthSubSubOption);
          }
        }
        if (dynamicDataObj.wind_speed !== null) {
          let selectedOption = {
            value: dynamicDataObj.wind_speed,
            label: dynamicDataObj.wind_speed
          };
          change(`windSpeed_${index}`, selectedOption);
        } else {
          let selectedOption = {
            value: '1',
            label: '1'
          };
          change(`windSpeed_${index}`, selectedOption);
        }
        if (dynamicDataObj.effect_application !== null) {
          let selectedOption = {
            value: dynamicDataObj.effect_application,
            label: dynamicDataObj.effect_application
          };
          change(`effect_${index}`, selectedOption);
        } else {
          let selectedOption = {
            value: '1',
            label: '1'
          };
          change(`effect_${index}`, selectedOption);
        }
        if (dynamicDataObj.harvestDate !== null) {
          change(`harvestdate_${index}`, dynamicDataObj.harvestDate);
        }
      }
    }
    if(list.waterJournalAdded) {
      this.setState({
        [`waterJournalOn_${index}`]: true
      });
    }
    //assigning the water journal values
    if(list.waterJournal !== null) {
      if(list.waterJournal.sprayerType === 'FIELD_SPRAYER') {
        this.setState({
          [`waterEquipmentUsed_${index}`]: true
        });
        if(list.waterJournal.lessThan2PCSlope && list.waterJournal.lessThan2PCSlope === true){
          this.setState({
            [`checkLessThan2PCSlope_${index}`]: true
          });
        }
        if(list.waterJournal.lessThan2PCSlope && list.waterJournal.lessThan2PCSlope === false){
          this.setState({
            [`checkLessThan2PCSlope_${index}`]: false
          });
        }
        change(`distanceToWaterBody_${index}`, list.waterJournal.distanceToWaterBody);
        change(`distanceToWaterWell_${index}`, list.waterJournal.distanceToWaterWell);
        change(`reqdVegetatedBufferZone_${index}`, list.waterJournal.reqdVegetatedBufferZone);
        change(`nozzleName_${index}`, list.waterJournal.nozzleName);
        change(`driftReduction_${index}`, list.waterJournal.driftReduction);
        change(`maxDrivingSpeed_${index}`, list.waterJournal.maxDrivingSpeed);
        change(`pressure_${index}`, list.waterJournal.pressure);
        change(`nozzleHeight_${index}`, list.waterJournal.nozzleHeight);
      } else {
        this.setState({
          [`waterEquipmentUsed_${index}`]: false
        });
        if(list.waterJournal.vegetatedBufferExists){
          this.setState({
            [`checkVegetatedBufferExists_${index}`]: true
          });
        }
        if(list.waterJournal.nozzlesProvideTargetMovement){
          this.setState({
            [`checkNozzlesProvideTargetMovement_${index}`]: true
          });
        }
        if(list.waterJournal.damperSetForOptimalDistribution){
          this.setState({
            [`checkDamperSetForOptimalDistribution_${index}`]: true
          });
        }
        if(list.waterJournal.oneEndClosedToAvoidSurfaceWater){
          this.setState({
            [`checkOneEndClosedToAvoidSurfaceWater_${index}`]: true
          });
        }
        change(`distanceToWaterBody_${index}`, list.waterJournal.distanceToWaterBody);
        change(`distanceToWaterWell_${index}`, list.waterJournal.distanceToWaterWell);
        change(`locationAndDistance_${index}`, list.waterJournal.locationAndDistance);
        change(`cultureBySpray_${index}`, list.waterJournal.cultureBySpray);
        change(`fieldAge_${index}`, list.waterJournal.fieldAge);
        change(`developmentStage_${index}`, list.waterJournal.developmentStage);
        change(`reqdDistanceToSurfaceWater_${index}`, list.waterJournal.reqdDistanceToSurfaceWater);
        change(`reqdVegetatedBufferZone_${index}`, list.waterJournal.reqdVegetatedBufferZone);
        change(`fogSprayerModel_${index}`, list.waterJournal.fogSprayerModel);
        change(`nozzleName_${index}`, list.waterJournal.nozzleName);
        change(`driftReduction_${index}`, list.waterJournal.driftReduction);
        change(`maxDrivingSpeed_${index}`, list.waterJournal.maxDrivingSpeed);
        change(`pressure_${index}`, list.waterJournal.pressure);
        change(`amount_${index}`, list.waterJournal.amount);
        change(`ptoSpeed_${index}`, list.waterJournal.ptoSpeed);
        change(`sprayingTechnique_${index}`, list.waterJournal.sprayingTechnique);
      }
    }
  }

  fetchVehicle = selectedVehicle => {
    const { taskDetails } = this.props;
    const { allVehicles } = taskDetails;
    return _.filter(
      allVehicles,
      item =>
        selectedVehicle.label === `${item.asset.brand} ${item.asset.model}` &&
        selectedVehicle.value === item.asset.assetId
    )[0];
  };

  fetchEquipment = selectedEquipment => {
    const { taskDetails } = this.props;
    const { allEquipments } = taskDetails;
    return _.filter(
      allEquipments,
      item =>
        selectedEquipment.label === `${item.asset.brand} ${item.asset.model}` &&
        selectedEquipment.value === item.asset.assetId
    )[0];
  };

  fetchDateTime = (list,index) => {
    if (list.startTime || list.endTime) {
      const tempStartDateTime = list.startTime
        ? GetValuesFromDate(new Date(ParseISOString(list.startTime)))
        : { date: '', time: '' };
      const tempEndDateTime = list.endTime
        ? GetValuesFromDate(new Date(ParseISOString(list.endTime)))
        : { date: '', time: '' };
      this.setState({
        [`startDate_${index}`]: tempStartDateTime.date,
        [`endDate_${index}`]: tempEndDateTime.date,
        [`startTime_${index}`]: tempStartDateTime.time,
        [`endTime_${index}`]: tempEndDateTime.time
      });
    }
  };

  calculateDuration = (list,index) => {
    let startDate = this.state[`startDate_${index}`];
    let startTime = this.state[`startTime_${index}`];
    let endDate = this.state[`endDate_${index}`];
    let endTime = this.state[`endTime_${index}`];
    let updateDuration = this.state[`updateDuration_${index}`];
    if(updateDuration) {
      const sDate = startDate && GetDateObj(startDate, startTime);
      const eDate = endDate && GetDateObj(endDate, endTime);
      if(sDate && eDate && eDate >= sDate) {
        const diffInMs = Math.abs(eDate - sDate);
        const hrsMin = SecondsTohoursMinutes(diffInMs / (1000));
        return `${PadString(hrsMin.hours)} : ${PadString(hrsMin.minutes)}`;
      }
    } else {
      if(list && list.netDuration !== null && list.netDuration !== 0) {
        const hrsMin = SecondsTohoursMinutes(list.netDuration);
        return `${PadString(hrsMin.hours)} : ${PadString(hrsMin.minutes)}`;
      } else if(list && list.netDuration === 0) {
        return '00 : 00';
      } else {
        return '-- : --';
      }
    }
    return '-- : --';
  };

  handleDateChange(e, name, index) {
    let splitDate = e.target.value.split(" ");
    if(name === 'startTime') {
      this.setState({  
        [`startDate_${index}`]: splitDate[0],
        [`startTime_${index}`]: splitDate[1],
        [`updateDuration_${index}`]: true
      });
    } else {
      this.setState({  
        [`endDate_${index}`]: splitDate[0],
        [`endTime_${index}`]: splitDate[1],
        [`updateDuration_${index}`]: true
      });
    }
  }

  getNumberOptions = () => {
    let result = [];
    result.push({value: '1', label: '1'});
    result.push({value: '2', label: '2'});
    result.push({value: '3', label: '3'});
    result.push({value: '4', label: '4'});
    result.push({value: '5', label: '5'});
    result.push({value: '6', label: '6'});
    result.push({value: '7', label: '7'});
    result.push({value: '8', label: '8'});
    result.push({value: '9', label: '9'});
    result.push({value: '10', label: '10'});
    result.push({value: '11', label: '11'});
    result.push({value: '12', label: '12'});
    result.push({value: '13', label: '13'});
    result.push({value: '14', label: '14'});
    result.push({value: '15', label: '15'});
    result.push({value: '16', label: '16'});
    result.push({value: '17', label: '17'});
    result.push({value: '18', label: '18'});
    result.push({value: '19', label: '19'});
    result.push({value: '20', label: '20'});
    return result;
  };

  onDropdownPestChange = (option, activitiesCommon, index) => {
    const { change } = this.props;
    change(`pestSubData_${index}`, []);
    this.setState({
      [`pestSubTypeOptions_${index}`]: []
    });
    if (option.value === UGRAS) {
      this.setState({
        [`pestSubTypeOptions_${index}`]: pestUgrasSubTypeOptions(activitiesCommon)
      });
    }
    if (option.value === POTETSYKDOMMER) {
      this.setState({
        [`pestSubTypeOptions_${index}`]: pestPotetsykdommerSubTypeOptions(activitiesCommon)
      });
    }
    if (option.value === SOPP) {
      this.setState({
        [`pestSubTypeOptions_${index}`]: pestSoppSubTypeOptions(activitiesCommon)
      });
    }
    if (option.value === SKADEDYR) {
      this.setState({
        [`pestSubTypeOptions_${index}`]: pestSkadedyrSubTypeOptions(activitiesCommon)
      });
    }
    if (option.value === MANGELSYKDOMMER) {
      this.setState({
        [`pestSubTypeOptions_${index}`]: pestMangelsykdommerSubTypeOptions(activitiesCommon)
      });
    }
    if (option.value === VEKSTAVSLUTNING) {
      this.setState({
        [`pestSubTypeOptions_${index}`]: pestVekstavslutningSubTypeOptions(activitiesCommon)
      });
    }
    if (option.value === VEKSTREGULERING) {
      this.setState({
        [`pestSubTypeOptions_${index}`]: pestVekstreguleringSubTypeOptions(activitiesCommon)
      });
    }
    if (option.value === BEISING) {
      this.setState({
        [`pestSubTypeOptions_${index}`]: pestBeisingSubTypeOptions(activitiesCommon)
      });
    }
  };

  onDropdownGrowthChange = (option, activitiesCommon, index) => {
    const { change } = this.props;
    change(`growthStageSub_${index}`, []);
    change(`growthStageSubSub_${index}`, []);
    this.setState({
      [`growthSubTypeOptions_${index}`]: [],
      [`growthSubSubTypeOptions_${index}`]: []
    });
    if (option.value === KORN) {
      this.setState({
        [`growthSubTypeOptions_${index}`]: growthStageSubTypeKornOptions(activitiesCommon)
      });
    }
    if (option.value === OLJEVEKSTER) {
      this.setState({
        [`growthSubTypeOptions_${index}`]: growthStageSubTypeOljeveksterOptions(activitiesCommon)
      });
    }
    if (option.value === ERTER) {
      this.setState({
        [`growthSubTypeOptions_${index}`]: growthStageSubTypeErterOptions(activitiesCommon)
      });
    }
    if (option.value === POTET) {
      this.setState({
        [`growthSubTypeOptions_${index}`]: growthStageSubTypePotetOptions(activitiesCommon)
      });
    }
    if (option.value === JORDBR) {
      this.setState({
        [`growthSubTypeOptions_${index}`]: growthStageSubTypeJordbrOptions(activitiesCommon)
      });
    }
    if (option.value === EPLER) {
      this.setState({
        [`growthSubTypeOptions_${index}`]: growthStageSubTypeEplerOptions(activitiesCommon)
      });
    }
    if (option.value === IKKEANGITT) {
      this.setState({
        [`growthSubTypeOptions_${index}`]: growthStageSubTypeIkkeAngittOptions(activitiesCommon)
      });
    }
  };

  onDropdownGrowthSubChange = (option, activitiesCommon, index) => {
    const { change } = this.props;
    change(`growthStageSubSub_${index}`, []);
    this.setState({
      [`growthSubSubTypeOptions_${index}`]: []
    });
    if (option.value === SPIRING) {
      this.setState({
        [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeSpiringOptions(activitiesCommon)
      });
    }
    if (option.value === BLADUTVIKLING_HOVEDSKUDD) {
      this.setState({
        [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeBladutviklingOptions(activitiesCommon)
      });
    }
    if (option.value === BUSKING_UTVIKLING_AV_SIDESKUDD) {
      this.setState({
        [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeBuskingOptions(activitiesCommon)
      });
    }
    if (option.value === STREKKING_HOVEDSKUDD) {
      this.setState({
        [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeStrekkingOptions(activitiesCommon)
      });
    }
    if (option.value === AKS_ELLER_TOPPDANNELSE) {
      this.setState({
        [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeAksOptions(activitiesCommon)
      });
    }
    if (option.value === SKYTING_HOVEDSKUDD) {
      this.setState({
        [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeSkytingOptions(activitiesCommon)
      });
    }
    if (option.value === BLOMSTRING_HOVEDSKUDD) {
      this.setState({
        [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeBlomstringOptions(activitiesCommon)
      });
    }
    if (option.value === FROKJERNE_UTVIKLING) {
      this.setState({
        [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeFrkjerneOptions(activitiesCommon)
      });
    }
    if (option.value === MODNING) {
      this.setState({
        [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeModningOptions(activitiesCommon)
      });
    }
    if (option.value === VISNING) {
      this.setState({
        [`growthSubSubTypeOptions_${index}`]: growthStageSubSubTypeVisningOptions(activitiesCommon)
      });
    }
  };

  changeWaterJournalToggle = (index) => {
    if(this.state[`waterJournalOn_${index}`] === true) {
      this.setState({
        [`waterJournalOn_${index}`]: false
      });
    } else {
      this.setState({
        [`waterJournalOn_${index}`]: true
      });
    }
  }

  changeWaterEquipmentUsed = (e, index) => {
    const { change } = this.props;
    this.setState({
      [`waterEquipmentUsed_${index}`]: e
    })
    this.setState({
      waterEquipmentUsed: e
    });
    change(this.state[`waterEquipmentUsed_${index}`], e);
  };

  changeLessThan2PCSlope1 = (e, index) => {
    const { change } = this.props;
    this.setState({
      [`checkLessThan2PCSlope_${index}`]: e
    });
    change(this.state[`checkLessThan2PCSlope_${index}`], e);
  };

  changeVegetatedBufferExists = (index) => {
    if(this.state[`checkVegetatedBufferExists_${index}`] === true) {
      this.setState({
        [`checkVegetatedBufferExists_${index}`]: false
      });
    } else {
      this.setState({
        [`checkVegetatedBufferExists_${index}`]: true
      });
    }
  };

  changeNozzlesProvideTargetMovement = (index) => {
    if(this.state[`checkNozzlesProvideTargetMovement_${index}`] === true) {
      this.setState({
        [`checkNozzlesProvideTargetMovement_${index}`]: false
      });
    } else {
      this.setState({
        [`checkNozzlesProvideTargetMovement_${index}`]: true
      });
    }
  }

  changeDamperSetForOptimalDistribution = (index) => {
    if(this.state[`checkDamperSetForOptimalDistribution_${index}`] === true) {
      this.setState({
        [`checkDamperSetForOptimalDistribution_${index}`]: false
      });
    } else {
      this.setState({
        [`checkDamperSetForOptimalDistribution_${index}`]: true
      });
    }
  }

  changeOneEndClosedToAvoidSurfaceWater = (index) => {
    if(this.state[`checkOneEndClosedToAvoidSurfaceWater_${index}`] === true) {
      this.setState({
        [`checkOneEndClosedToAvoidSurfaceWater_${index}`]: false
      });
    } else {
      this.setState({
        [`checkOneEndClosedToAvoidSurfaceWater_${index}`]: true
      });
    }
  }

  handleSelectChange = (event, index) => {
    this.setState({
      [`stateFieldName_${index}`]: event.target.value
    });
  };

  getValue = (list, index) => {
    const { intl, fieldOptions, vehicleOptions, equipmentOptions, weatherOptions, assigneeOptions, activitiesCommon } = this.props;
    let data = '';
    data = (
      <tr className='task-batch-border-top'>
        <td className="task-batch-padding task-batch-right-border task-batch-border-top">
          <div className="dropdowns dropdown-padding">
            <select value={this.state[`stateFieldName_${index}`]} name={`fieldName_${index}`} 
              onChange={(value) => this.handleSelectChange(value, index)} className="customized-select-dropdown customized-select-dropdown-min-width">
              {fieldOptions.map((option, index) => (
                <option key={index} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
        </td>
        <td className="task-batch-padding task-batch-right-border task-batch-border-top">
          <div className="dropdowns">
            <Field
              name={`vehicle_${index}`}
              component={SelectControl}
              styleName="select-box-common"
              placeholder={intl.formatMessage({ id: 'TASK_BATCH_VEHICLE' })}
              options={vehicleOptions}
              controlId={`vehicle_${index}`}
            />
          </div>
        </td>
        <td className="task-batch-padding task-batch-right-border task-batch-border-top">
          <div className="dropdowns">
            <Field
              name={`equipment_${index}`}
              component={SelectControl}
              styleName="select-box-common"
              placeholder={intl.formatMessage({ id: 'TASK_BATCH_EQUIPMENT' })}
              options={equipmentOptions}
              controlId={`equipment_${index}`}
            />
          </div>
        </td>
        <td className="task-batch-padding-220 task-batch-right-border task-batch-border-top">
          <Field
            name={`startTime_${index}`}
            placeholder={intl.formatMessage({ id: 'TASK_BATCH_TIME_PATTERN' })}
            component={TextBoxControl}
            controlId={`startTime_${index}`}
            type="text"
            stlyeClass="input-textbox-task-batch"
            onChange={(value) => this.handleDateChange(value, 'startTime', index)}
          />
        </td>
        <td className="task-batch-padding-220 task-batch-right-border task-batch-border-top">
          <Field
            name={`endTime_${index}`}
            placeholder={intl.formatMessage({ id: 'TASK_BATCH_TIME_PATTERN' })}
            component={TextBoxControl}
            controlId={`endTime_${index}`}
            type="text"
            stlyeClass="input-textbox-task-batch"
            onChange={(value) => this.handleDateChange(value, 'endTime', index)}
          />
        </td>
        <td className="task-batch-padding-160 task-batch-right-border task-batch-border-top">
          <span className="input-content-task-batch">{this.calculateDuration(list,index)}</span>
        </td>
        <td className="task-batch-padding-200 task-batch-right-border task-batch-border-top">
          <div className="dropdowns">
            <Field
              name={`weather_${index}`}
              component={SelectControl}
              styleName="select-box-common"
              placeholder={intl.formatMessage({ id: 'TASK_BATCH_WEATHER' })}
              options={weatherOptions}
              controlId={`weather_${index}`}
            />
          </div>
        </td>
        <td className="task-batch-padding-160 task-batch-right-border task-batch-border-top">
          <div className="dropdowns">
            <Field
              name={`windSpeed_${index}`}
              component={SelectControl}
              styleName="select-box-common"
              options={this.getNumberOptions()}
              controlId={`windSpeed_${index}`}
            />
          </div>
        </td>
        {this.getPesticideValueList(index)}
        <td className="task-batch-padding-160 task-batch-right-border task-batch-border-top">
          <Field
            name={`waterAmount_${index}`}
            placeholder={intl.formatMessage({ id: 'ENTER_WATER_AMOUNT' })}
            component={TextBoxControl}
            controlId={`waterAmount_${index}`}
            type="text"
            stlyeClass="input-textbox-task-batch"
          />
        </td>
        <td className="task-batch-padding-160 task-batch-right-border task-batch-border-top">
          <div className="dropdowns">
            <Field
              name={`waterUnit_${index}`}
              component={SelectControl}
              styleName="select-box-common"
              placeholder={intl.formatMessage({ id: 'CT_UNIT' })}
              options={waterUnitOptions(activitiesCommon)}
              controlId={`waterUnit_${index}`}
            />
          </div>
        </td>
        <td className="task-batch-padding task-batch-right-border task-batch-border-top">
          <Field
            name={`crop_${index}`}
            placeholder={intl.formatMessage({ id: 'ENTER_CROP' })}
            component={TextBoxControl}
            controlId={`crop_${index}`}
            type="text"
            stlyeClass="input-textbox-task-batch"
          />
        </td>
        <td className="task-batch-padding task-batch-right-border task-batch-border-top">
          <Field
            name={`reason_${index}`}
            placeholder={intl.formatMessage({ id: 'ENTER_REASON' })}
            component={TextBoxControl}
            controlId={`reason_${index}`}
            type="text"
            stlyeClass="input-textbox-task-batch"
          />
        </td>
        <td className="task-batch-padding task-batch-right-border task-batch-border-top">
          <div className="dropdowns">
            <Field
              name={`pestData_${index}`}
              component={SelectControl}
              styleName="select-box-common"
              placeholder={intl.formatMessage({ id: 'CT_PEST' })}
              options={pestOptions(activitiesCommon)}
              onChange={v => this.onDropdownPestChange(v, activitiesCommon, index)}
              controlId={`pestData_${index}`}
            />
          </div>
        </td>
        <td className="task-batch-padding task-batch-right-border task-batch-border-top">
          <div className="dropdown">
            <Field
              name={`pestSubData_${index}`}
              component={SelectControl}
              styleName="select-box-common-pest"
              placeholder={intl.formatMessage({ id: 'CT_PESTSUBTYPES' })}
              options={this.state[`pestSubTypeOptions_${index}`]}
              controlId={`pestSubData_${index}`}
              isMulti={true}
            />
          </div>
        </td>
        <td className="task-batch-padding task-batch-right-border task-batch-border-top">
          <div className="dropdowns">
            <Field
              name={`growthStage_${index}`}
              component={SelectControl}
              styleName="select-box-common"
              placeholder={intl.formatMessage({ id: 'CT_GS' })}
              options={growthStageOptions(activitiesCommon)}
              onChange={v => this.onDropdownGrowthChange(v, activitiesCommon, index)}
              controlId={`growthStage_${index}`}
            />
          </div>
        </td>
        <td className="task-batch-padding task-batch-right-border task-batch-border-top">
          <div className="dropdowns">
            <Field
              name={`growthStageSub_${index}`}
              component={SelectControl}
              styleName="select-box-common"
              placeholder={intl.formatMessage({ id: 'CT_GSSUB' })}
              options={this.state[`growthSubTypeOptions_${index}`]}
              onChange={v => this.onDropdownGrowthSubChange(v, activitiesCommon, index)}
              controlId={`growthStageSub_${index}`}
            />
          </div>
        </td>
        <td className="task-batch-padding task-batch-right-border task-batch-border-top">
          <div className="dropdowns">
            <Field
              name={`growthStageSubSub_${index}`}
              component={SelectControl}
              styleName="select-box-common"
              placeholder={intl.formatMessage({ id: 'CT_GSSUBTYPE' })}
              options={this.state[`growthSubSubTypeOptions_${index}`]}
              controlId={`growthStageSubSub_${index}`}
            />
          </div>
        </td>
        <td className="task-batch-padding-180 task-batch-right-border task-batch-border-top">
          <div className="dropdowns">
            <Field
              name={`effect_${index}`}
              component={SelectControl}
              styleName="select-box-common"
              options={this.getNumberOptions()}
              controlId={`effect_${index}`}
            />
          </div>
        </td>
        <td className="task-batch-padding-180 task-batch-right-border task-batch-border-top">
          <Field
            name={`harvestdate_${index}`}
            placeholdertext={intl.formatMessage({ id: 'TASK_BATCH_HARVEST_PATTERN' })}
            component={TextBoxControl}
            controlId={`harvestdate_${index}`}
            type="text"
            stlyeClass="input-textbox-task-batch"
          />
        </td>
        <td className="task-batch-padding-120 task-batch-right-border task-batch-border-top task-batch-float-left">
          <ToggleButton
            inactiveLabel={intl.formatMessage({ id: 'NO' })}
            activeLabel={intl.formatMessage({ id: 'YES' })}
            colors={{
              inactive: {
                base: 'rgb(42, 60, 99)',
                hover: 'rgb(42, 60, 99)',
              }
            }}
            value={this.state[`waterJournalOn_${index}`]}
            onToggle = {() => this.changeWaterJournalToggle(index)}
          />
        </td>
        <td className="task-batch-padding-200 task-batch-right-border task-batch-border-top task-batch-float-left">
          <div>
            <span className="water-journal__yes-no">
              <RadioButton
                id={`sprayerType1_${index}`}
                labelText={intl.formatMessage({ id: 'WATER_JOURNAL_EQUIPMENT_USED_OPTION1' })}
                disabled={!this.state[`waterJournalOn_${index}`]}
                name={`sprayerType1_${index}`}
                size="small"
                checked={this.state[`waterEquipmentUsed_${index}`] === true}
                onChange={() => this.changeWaterEquipmentUsed(true, index)}
              />
            </span>
            <span className="water-journal__yes-no">
              <RadioButton
                id={`sprayerType2_${index}`}
                labelText={intl.formatMessage({ id: 'WATER_JOURNAL_EQUIPMENT_USED_OPTION2' })}
                disabled={!this.state[`waterJournalOn_${index}`]}
                name={`sprayerType2_${index}`}
                size="small"
                checked={this.state[`waterEquipmentUsed_${index}`] === false}
                onChange={() => this.changeWaterEquipmentUsed(false, index)}
              />
            </span>
          </div>
        </td>
        <td className="task-batch-padding-220-units task-batch-right-border task-batch-border-top">
          <Field
            name={`distanceToWaterBody_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_METER" })}
            key={`distanceToWaterBody_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`]}
          />
        </td>
        <td className="task-batch-padding-220-units task-batch-right-border task-batch-border-top">
          <Field
            name={`distanceToWaterWell_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_METER" })}
            key={`distanceToWaterWell_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`]}
          />
        </td>
        <td className="task-batch-padding-200 task-batch-right-border task-batch-border-top task-batch-float-left">
          <div>
            <span className="water-journal__yes-no">
              <RadioButton
                id={`lessThan2PCSlope1_${index}`}
                labelText={intl.formatMessage({ id: 'YES' })}
                disabled={!this.state[`waterJournalOn_${index}`] || !this.state[`waterEquipmentUsed_${index}`]}
                name={`lessThan2PCSlope1_${index}`}
                size="small"
                checked={this.state[`checkLessThan2PCSlope_${index}`]}
                onChange={() => this.changeLessThan2PCSlope1(true, index)}
              />
            </span>
            <span className="water-journal__yes-no">
              <RadioButton
                id={`lessThan2PCSlope2_${index}`}
                labelText={intl.formatMessage({ id: 'NO' })}
                disabled={!this.state[`waterJournalOn_${index}`] || !this.state[`waterEquipmentUsed_${index}`]}
                name={`lessThan2PCSlope2_${index}`}
                size="small"
                checked={!this.state[`checkLessThan2PCSlope_${index}`]}
                onChange={() => this.changeLessThan2PCSlope1(false, index)}
              />
            </span>
          </div>
        </td>
        <td className="task-batch-padding-180 task-batch-right-border task-batch-border-top">
          <Field
            name={`reqdVegetatedBufferZone_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            key={`reqdVegetatedBufferZone_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`]}
          />
        </td>
        <td className="task-batch-padding-180 task-batch-right-border task-batch-border-top">
          <Field
            name={`nozzleName_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            key={`nozzleName_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`]}
          />
        </td>
        <td className="task-batch-padding-200-units task-batch-right-border task-batch-border-top">
          <Field
            name={`driftReduction_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_PERCENTAGE" })}
            key={`driftReduction_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`]}
          />
        </td>
        <td className="task-batch-padding-220-units task-batch-right-border task-batch-border-top">
          <Field
            name={`maxDrivingSpeed_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_KM" })}
            key={`maxDrivingSpeed_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`]}
          />
        </td>
        <td className="task-batch-padding-220-units task-batch-right-border task-batch-border-top">
          <Field
            name={`pressure_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_BAR" })}
            key={`pressure_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`]}
          />
        </td>
        <td className="task-batch-padding-220-units task-batch-right-border task-batch-border-top">
          <Field
            name={`nozzleHeight_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_CM" })}
            key={`nozzleHeight_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`] || !this.state[`waterEquipmentUsed_${index}`]}
          />
        </td>
        <td className="task-batch-padding-160 task-batch-right-border task-batch-border-top task-batch-float-left">
          <ToggleButton
            inactiveLabel={intl.formatMessage({ id: 'NO' })}
            activeLabel={intl.formatMessage({ id: 'YES' })}
            colors={{
              inactive: {
                base: 'rgb(42, 60, 99)',
                hover: 'rgb(42, 60, 99)',
              }
            }}
            value={this.state[`checkVegetatedBufferExists_${index}`]}
            onToggle = {() => (this.state[`waterJournalOn_${index}`] && !this.state[`waterEquipmentUsed_${index}`]) ? this.changeVegetatedBufferExists(index) : ''}

          />
        </td>
        <td className="task-batch-padding-160 task-batch-right-border task-batch-border-top">
          <Field
            name={`locationAndDistance_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            key={`locationAndDistance_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`] || this.state[`waterEquipmentUsed_${index}`] || !this.state[`checkVegetatedBufferExists_${index}`]}
          />
        </td>
        <td className="task-batch-padding-160-units task-batch-right-border task-batch-border-top">
          <Field
            name={`cultureBySpray_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_METER" })}
            key={`cultureBySpray_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`] || this.state[`waterEquipmentUsed_${index}`]}
          />
        </td>
        <td className="task-batch-padding-220-units task-batch-right-border task-batch-border-top">
          <Field
            name={`fieldAge_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_AR" })}
            key={`fieldAge_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`] || this.state[`waterEquipmentUsed_${index}`]}
          />
        </td>
        <td className="task-batch-padding-200 task-batch-right-border task-batch-border-top">
          <Field
            name={`developmentStage_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            key={`developmentStage_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`] || this.state[`waterEquipmentUsed_${index}`]}
          />
        </td>
        <td className="task-batch-padding-180 task-batch-right-border task-batch-border-top">
          <Field
            name={`reqdDistanceToSurfaceWater_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            key={`reqdDistanceToSurfaceWater_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`] || this.state[`waterEquipmentUsed_${index}`]}
          />
        </td>
        <td className="task-batch-padding-180 task-batch-right-border task-batch-border-top">
          <Field
            name={`fogSprayerModel_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            key={`fogSprayerModel_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`] || this.state[`waterEquipmentUsed_${index}`]}
          />
        </td>
        <td className="task-batch-padding-220-units task-batch-right-border task-batch-border-top">
          <Field
            name={`amount_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_LITER" })}
            key={`amount_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`] || this.state[`waterEquipmentUsed_${index}`]}
          />
        </td>
        <td className="task-batch-padding-units task-batch-right-border task-batch-border-top">
          <Field
            name={`ptoSpeed_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            units={intl.formatMessage({ id: "WATER_JOURNAL_UNIT_OMDR" })}
            key={`ptoSpeed_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`] || this.state[`waterEquipmentUsed_${index}`]}
          />
        </td>
        <td className="task-batch-padding-180 task-batch-right-border task-batch-border-top task-batch-float-left">
          <ToggleButton
            inactiveLabel={intl.formatMessage({ id: 'NO' })}
            activeLabel={intl.formatMessage({ id: 'YES' })}
            colors={{
              inactive: {
                base: 'rgb(42, 60, 99)',
                hover: 'rgb(42, 60, 99)',
              }
            }}
            value={this.state[`checkNozzlesProvideTargetMovement_${index}`]}
            onToggle = {() => (this.state[`waterJournalOn_${index}`] && !this.state[`waterEquipmentUsed_${index}`]) ? this.changeNozzlesProvideTargetMovement(index) : ''}
          />
        </td>
        <td className="task-batch-padding-180 task-batch-right-border task-batch-border-top task-batch-float-left">
          <ToggleButton
            inactiveLabel={intl.formatMessage({ id: 'NO' })}
            activeLabel={intl.formatMessage({ id: 'YES' })}
            colors={{
              inactive: {
                base: 'rgb(42, 60, 99)',
                hover: 'rgb(42, 60, 99)',
              }
            }}
            value={this.state[`checkDamperSetForOptimalDistribution_${index}`]}
            onToggle = {() => (this.state[`waterJournalOn_${index}`] && !this.state[`waterEquipmentUsed_${index}`]) ? this.changeDamperSetForOptimalDistribution(index) : ''}
          />
        </td>
        <td className="task-batch-padding-180 task-batch-right-border task-batch-border-top task-batch-float-left">
          <ToggleButton
            inactiveLabel={intl.formatMessage({ id: 'NO' })}
            activeLabel={intl.formatMessage({ id: 'YES' })}
            colors={{
              inactive: {
                base: 'rgb(42, 60, 99)',
                hover: 'rgb(42, 60, 99)',
              }
            }}
            value={this.state[`checkOneEndClosedToAvoidSurfaceWater_${index}`]}
            onToggle = {() => (this.state[`waterJournalOn_${index}`] && !this.state[`waterEquipmentUsed_${index}`]) ? this.changeOneEndClosedToAvoidSurfaceWater(index) : ''}
          />
        </td>
        <td className="task-batch-padding-200 task-batch-right-border task-batch-border-top">
          <Field
            name={`sprayingTechnique_${index}`}
            component={TextBoxControl}
            type="text"
            styleName={`task-margin-top`}
            key={`sprayingTechnique_${index}`}
            disabled={!this.state[`waterJournalOn_${index}`] || this.state[`waterEquipmentUsed_${index}`]}
          />
        </td>
        <td className="task-batch-padding task-batch-right-border task-batch-border-top">
          <div className="dropdowns">
            <Field
              name={`assignee_${index}`}
              component={SelectControl}
              styleName="select-box-common user-dropdown-normal"
              placeholder={intl.formatMessage({ id: 'ASSIGNEE' })}
              options={assigneeOptions}
              controlId={`assignee_${index}`}
            />
          </div>
        </td>
        <td className="task-batch-padding task-batch-right-border task-batch-border-top">
          <Field
            name={`notes_${index}`}
            component={TextAreaControl}
            controlId={`notes_${index}`}
            stlyeClass="textareafield-placeholder-size"
          />
        </td>
      </tr>
    );
    return data;
  }

  getPesticideHeader = () => {
    const { intl } = this.props;
    let data = "";
    data = (
      <>
        <td className="task-batch-padding task-batch-right-border">
          {intl.formatMessage({ id: 'CT_PESTICIDE' })}
        </td>
        <td className="task-batch-padding-160 task-batch-right-border">
          {intl.formatMessage({ id: 'CT_AMOUNT' })}
        </td>
        <td className="task-batch-padding-160 task-batch-right-border">
          {intl.formatMessage({ id: 'CT_UNIT' })}
        </td>
      </>
    );
    return data;
  }

  getPesticideValue = (arrayIndex, index) => {
    const { intl, activitiesCommon } = this.props;
    let data = "";
    data = (
      <>
        <td className="task-batch-padding task-batch-right-border task-batch-border-top">
          <div className="dropdowns">
            <Field
              name={`pesticideName_${arrayIndex}_${index}`}
              component={SelectControl}
              styleName="select-box-common"
              placeholder={intl.formatMessage({ id: 'CT_PESTICIDE' })}
              options={pesticideTypeOptions(activitiesCommon)}
              controlId={`pesticideName_${arrayIndex}_${index}`}
            />
          </div>
        </td>
        <td className="task-batch-padding-160 task-batch-right-border task-batch-border-top">
          <Field
            name={`pesticideAmount_${arrayIndex}_${index}`}
            placeholder={intl.formatMessage({ id: 'ENTER_AMOUNT' })}
            component={TextBoxControl}
            controlId={`pesticideAmount_${arrayIndex}_${index}`}
            type="text"
            stlyeClass="input-textbox-task-batch"
          />
        </td>
        <td className="task-batch-padding-160 task-batch-right-border task-batch-border-top">
          <div className="dropdowns">
            <Field
              name={`pesticideUnit_${arrayIndex}_${index}`}
              component={SelectControl}
              styleName="select-box-common"
              placeholder={intl.formatMessage({ id: 'CT_UNIT' })}
              options={pesticideUnitOptions()}
              controlId={`pesticideUnit_${arrayIndex}_${index}`}
            />
          </div>
        </td>
      </>
    );
    return data;
  }

  getValueList = () => {
    const { taskList } = this.props;
    return taskList.map((list, index) =>
      this.getValue(list, index)
    );
  }

  getPesticideHeaderList = () => {
    const { pesticideSize } = this.props;
    let result = [];
    for(var i=1; i<=pesticideSize; i++){
      result.push({value: i, label: i});
    }
    return result.map((list) =>
      this.getPesticideHeader()
    );
  }

  getPesticideValueList = (arrayIndex) => {
    const { pesticideSize } = this.props;
    let result = [];
    for(var i=1; i<=pesticideSize; i++){
      result.push({value: i, label: i});
    }
    return result.map((list, index) =>
      this.getPesticideValue(arrayIndex, index)
    );
  }

  handleSubmit = values => {
    const { taskList, createTask, activeFarm, changeSetSuccessMessage } = this.props;
    const { errorFieldsState } = this.state;
    const errors = validate(values, this.props);
    if(errorFieldsState === null) {
      this.setState({
        errorFieldsState: errors
      });
    }
    if (Object.keys(errors).length > 0) {
      handleFocusOnError(errors, errorFieldsState);
      return;
    }
    let taskValueArray = [];
    taskList.map((task, index) => {
      const taskValue = {
        "farmId": task.farmId,
        "activityId": task.activityId,
        "fieldId": this.state[`stateFieldName_${index}`]
      }
      if(values[`vehicle_${index}`]) {
        let vehicle = this.fetchVehicle(values[`vehicle_${index}`]);
        taskValue["vehicleAssetId"] = vehicle && vehicle.asset.assetId;
        taskValue["vehicleAssetAssignmentId"] = vehicle && vehicle.assetAssignmentId;
      }
      if(values[`equipment_${index}`]) {
        let equipment = this.fetchEquipment(values[`equipment_${index}`]);
        taskValue["equipmentAssetId"] = equipment && equipment.asset.assetId;
        taskValue["equipmentAssetAssignmentId"] = equipment && equipment.assetAssignmentId;
      }
      if(values[`assignee_${index}`]) {
        taskValue["assigneeId"] = values[`assignee_${index}`].value;
      }
      if(values[`weather_${index}`]) {
        taskValue["weather"] = values[`weather_${index}`].value;
      }
      if(task.status) {
        taskValue["status"] = task.status;
      }
      if(values[`notes_${index}`]) {
        taskValue["notes"] = values[`notes_${index}`];
      }
      if(values[`startTime_${index}`] && values[`endTime_${index}`]) {
        let startTimeSplit = values[`startTime_${index}`].split(" ");
        let endTimeSplit = values[`endTime_${index}`].split(" ");
        let startTime = getValueByDateAndTime(startTimeSplit[0],startTimeSplit[1]);
        let endTime = getValueByDateAndTime(endTimeSplit[0],endTimeSplit[1]);
        taskValue["startTime"] = startTime;
        taskValue["endTime"] = endTime;

        const diffInMs = Math.abs(new Date(startTime) - new Date(endTime));
        taskValue["netDuration"] = diffInMs / 1000;
      }
      taskValue["id"] = task.id;
      taskValue["waterJournalAdded"] = this.state[`waterJournalOn_${index}`];
      taskValue["waterJournal"] = this.getWaterJounalValue(values, index);
      taskValue["dynamicData"] = JSON.stringify(this.getDynamicValue(task, values, index));
      taskValueArray.push(taskValue);
      return null;
    });
    changeSetSuccessMessage(true);
    createTask(taskValueArray, "batchId", true, activeFarm.farm.id, "complete");
  }

  getWaterJounalValue = (values, index) => {
    if(this.state[`waterJournalOn_${index}`]) {
      let waterJournal = {};
      if(this.state[`waterEquipmentUsed_${index}`]) {
        waterJournal["sprayerType"] = "FIELD_SPRAYER";
        waterJournal["distanceToWaterBody"] = values[`distanceToWaterBody_${index}`] ? values[`distanceToWaterBody_${index}`] : null;
        waterJournal["distanceToWaterWell"] = values[`distanceToWaterWell_${index}`] ? values[`distanceToWaterWell_${index}`] : null;
        waterJournal["lessThan2PCSlope"] = this.state[`checkLessThan2PCSlope_${index}`];
        waterJournal["reqdVegetatedBufferZone"] = values[`reqdVegetatedBufferZone_${index}`] ? values[`reqdVegetatedBufferZone_${index}`] : null;
        waterJournal["nozzleName"] = values[`nozzleName_${index}`] ? values[`nozzleName_${index}`] : null;
        waterJournal["driftReduction"] = values[`driftReduction_${index}`] ? values[`driftReduction_${index}`] : null;
        waterJournal["maxDrivingSpeed"] = values[`maxDrivingSpeed_${index}`] ? values[`maxDrivingSpeed_${index}`] : null;
        waterJournal["pressure"] = values[`pressure_${index}`] ? values[`pressure_${index}`] : null;
        waterJournal["nozzleHeight"] = values[`nozzleHeight_${index}`] ? values[`nozzleHeight_${index}`] : null;
        waterJournal["vegetatedBufferExists"] = null;
        waterJournal["locationAndDistance"] = null;
        waterJournal["cultureBySpray"] = null;
        waterJournal["fieldAge"] = null;
        waterJournal["developmentStage"] = null;
        waterJournal["reqdDistanceToSurfaceWater"] = null;
        waterJournal["fogSprayerModel"] = null;
        waterJournal["amount"] = null;
        waterJournal["ptoSpeed"] = null;
        waterJournal["nozzlesProvideTargetMovement"] = null;
        waterJournal["damperSetForOptimalDistribution"] = null;
        waterJournal["oneEndClosedToAvoidSurfaceWater"] = null;
        waterJournal["sprayingTechnique"] = null;
      } else {
        waterJournal["sprayerType"] = "FOG_SPRAYER";
        waterJournal["distanceToWaterBody"] = values[`distanceToWaterBody_${index}`] ? values[`distanceToWaterBody_${index}`] : null;
        waterJournal["distanceToWaterWell"] = values[`distanceToWaterWell_${index}`] ? values[`distanceToWaterWell_${index}`] : null;
        waterJournal["vegetatedBufferExists"] = this.state[`checkVegetatedBufferExists_${index}`];
        if(this.state[`checkVegetatedBufferExists_${index}`]) {
          waterJournal["locationAndDistance"] = values[`locationAndDistance_${index}`] ? values[`locationAndDistance_${index}`] : null;
        } else {
          waterJournal["locationAndDistance"] = null;
        }
        waterJournal["cultureBySpray"] = values[`cultureBySpray_${index}`] ?  values[`cultureBySpray_${index}`] : null;
        waterJournal["fieldAge"] =  values[`fieldAge_${index}`] ? values[`fieldAge_${index}`] : null;
        waterJournal["developmentStage"] = values[`developmentStage_${index}`] ? values[`developmentStage_${index}`] : null;
        waterJournal["reqdDistanceToSurfaceWater"] = values[`reqdDistanceToSurfaceWater_${index}`] ? values[`reqdDistanceToSurfaceWater_${index}`] : null;
        waterJournal["reqdVegetatedBufferZone"] = values[`reqdVegetatedBufferZone_${index}`] ? values[`reqdVegetatedBufferZone_${index}`] : null;
        waterJournal["fogSprayerModel"] = values[`fogSprayerModel_${index}`] ? values[`fogSprayerModel_${index}`] : null;
        waterJournal["nozzleName"] = values[`nozzleName_${index}`] ? values[`nozzleName_${index}`] : null;
        waterJournal["driftReduction"] = values[`driftReduction_${index}`] ? values[`driftReduction_${index}`] : null;
        waterJournal["maxDrivingSpeed"] = values[`maxDrivingSpeed_${index}`] ? values[`maxDrivingSpeed_${index}`] : null;            
        waterJournal["pressure"] = values[`pressure_${index}`] ? values[`pressure_${index}`] : null;
        waterJournal["amount"] = values[`amount_${index}`] ? values[`amount_${index}`] : null;
        waterJournal["ptoSpeed"] = values[`ptoSpeed_${index}`] ? values[`ptoSpeed_${index}`] : null;
        waterJournal["nozzlesProvideTargetMovement"] = this.state[`checkNozzlesProvideTargetMovement_${index}`];
        waterJournal["damperSetForOptimalDistribution"] = this.state[`checkDamperSetForOptimalDistribution_${index}`];
        waterJournal["oneEndClosedToAvoidSurfaceWater"] = this.state[`checkOneEndClosedToAvoidSurfaceWater_${index}`];
        waterJournal["sprayingTechnique"] = values[`sprayingTechnique_${index}`] ? values[`sprayingTechnique_${index}`] : null;
      }
      return waterJournal;
    } else {
      return null;
    }
  }

  getDynamicValue = (task, values, index) => {
    const { selectedActivity, pesticideSize } = this.props;
    let dynamicData = {};
    let pestisideType = [];
    dynamicData["activity_id"] = selectedActivity.value;
    dynamicData["activity_name"] = selectedActivity.label;
    
    for(var i=0; i<pesticideSize; i++){
      let pestisideTypeValue = {};
      if(values[`pesticideAmount_${index}_${i}`] || values[`pesticideName_${index}_${i}`] || values[`pesticideUnit_${index}_${i}`]) {
        pestisideTypeValue["amount"] = values[`pesticideAmount_${index}_${i}`];
        pestisideTypeValue["name"] = values[`pesticideName_${index}_${i}`] ? values[`pesticideName_${index}_${i}`].value : '';
        pestisideTypeValue["unit"] = values[`pesticideUnit_${index}_${i}`] ? values[`pesticideUnit_${index}_${i}`].value : '';
        pestisideType.push(pestisideTypeValue);
      }        
    }
    dynamicData["pestiside_type"] = pestisideType;
    if(values[`waterAmount_${index}`]) {
      dynamicData["water_amount"] = values[`waterAmount_${index}`];
    }
    if(values[`waterUnit_${index}`]) {
      dynamicData["water_unit"] = values[`waterUnit_${index}`].value;
    }
    if(values[`crop_${index}`]) {
      dynamicData["crop"] = values[`crop_${index}`];
    }
    if(values[`reason_${index}`]) {
      dynamicData["reason"] = values[`reason_${index}`];
    }
    if(values[`pestData_${index}`]) {
      dynamicData["pest_data"] = values[`pestData_${index}`].value;
    }
    if(values[`pestSubData_${index}`]) {
      const subPestTypeArray = [];
      values[`pestSubData_${index}`].forEach(list => {
        subPestTypeArray.push(list.value);
      });
      if(subPestTypeArray.length > 0) {
        dynamicData["pest_sub_list"] = subPestTypeArray;
      }
    }
    if(values[`growthStage_${index}`]) {
      dynamicData["growthStage"] = values[`growthStage_${index}`].value;
    }
    if(values[`growthStageSub_${index}`]) {
      dynamicData["growthStage_sub"] = values[`growthStageSub_${index}`].value;
    }
    if(values[`growthStageSubSub_${index}`]) {
      dynamicData["growthStage_sub_subtype"] = values[`growthStageSubSub_${index}`].value;
    }
    if(values[`windSpeed_${index}`]) {
      dynamicData["wind_speed"] = values[`windSpeed_${index}`].value;
    }
    if(values[`effect_${index}`]) {
      dynamicData["effect_application"] = values[`effect_${index}`].value;
    }
    if(values[`harvestdate_${index}`]) {
      dynamicData["harvestDate"] = values[`harvestdate_${index}`];
    }
    return dynamicData;
  };

  render() {
    const { intl, taskList, handleSubmit, actId, taskDetails } = this.props;
    return (
      (actId !== null && taskList.length > 0) ?
      <form onSubmit={handleSubmit(this.handleSubmit)}>
      {taskDetails.isLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%', height: '55vh'}}><Loader styleName="fixed-loader" /></div>}
      <div className="task-batch-textcontainer table-fixed-div">
        <table cellSpacing="0" cellPadding="0" className={`task-batch-div-header`}>
          <thead>
            <tr>
              <td className="task-batch-padding task-batch-right-border">
                {intl.formatMessage({ id: 'TASK_BATCH_FIELD_NAME' })}
              </td>
              <td className="task-batch-padding task-batch-right-border">
                {intl.formatMessage({ id: 'TASK_BATCH_VEHICLE' })}
              </td>
              <td className="task-batch-padding task-batch-right-border">
                {intl.formatMessage({ id: 'TASK_BATCH_EQUIPMENT' })}
              </td>
              <td className="task-batch-padding-220 task-batch-right-border">
                {intl.formatMessage({ id: 'TASK_BATCH_START_TIME' })}
              </td>
              <td className="task-batch-padding-220 task-batch-right-border">
                {intl.formatMessage({ id: 'TASK_BATCH_END_TIME' })}
              </td>
              <td className="task-batch-padding-160 task-batch-right-border">
                {intl.formatMessage({ id: 'TASK_BATCH_DURATION' })}
              </td>
              <td className="task-batch-padding-200 task-batch-right-border">
                {intl.formatMessage({ id: 'TASK_BATCH_WEATHER' })}
              </td>
              <td className="task-batch-padding-160 task-batch-right-border">
                {intl.formatMessage({ id: 'WIND' })} {intl.formatMessage({ id: 'CT_W_U' })}
              </td>
              {this.getPesticideHeaderList()}
              <td className="task-batch-padding-160 task-batch-right-border">
                {intl.formatMessage({ id: 'CT_WATERAMOUNT' })}
              </td>
              <td className="task-batch-padding-160 task-batch-right-border">
                {intl.formatMessage({ id: 'CT_UNIT' })}
              </td>
              <td className="task-batch-padding task-batch-right-border">
                {intl.formatMessage({ id: 'CT_CR' })}
              </td>
              <td className="task-batch-padding task-batch-right-border">
                {intl.formatMessage({ id: 'CT_RFPA' })}
              </td>
              <td className="task-batch-padding task-batch-right-border">
                {intl.formatMessage({ id: 'CT_PEST' })}
              </td>
              <td className="task-batch-padding task-batch-right-border">
                {intl.formatMessage({ id: 'CT_PESTSUBTYPES' })}
              </td>
              <td className="task-batch-padding task-batch-right-border">
                {intl.formatMessage({ id: 'CT_GS' })}
              </td>
              <td className="task-batch-padding task-batch-right-border">
                {intl.formatMessage({ id: 'CT_GSSUB' })}
              </td>
              <td className="task-batch-padding task-batch-right-border">
                {intl.formatMessage({ id: 'CT_GSSUBTYPE' })}
              </td>
              <td className="task-batch-padding-180 task-batch-right-border">
                {intl.formatMessage({ id: 'CT_EOA' })}
              </td>
              <td className="task-batch-padding-180 task-batch-right-border">
                {intl.formatMessage({ id: 'CT_FVHD' })}
              </td>
              <td className="task-batch-padding-120 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_ADD' })}
              </td>
              <td className="task-batch-padding-200 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_EQUIPMENT_USED' })}
              </td>
              <td className="task-batch-padding-220 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_DISTANCE_TO_WATER_BODY' })}
              </td>
              <td className="task-batch-padding-220 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_DISTANCE_TO_WATER_WELL' })}
              </td>
              <td className="task-batch-padding-200 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_PC_SLOPE' })}
              </td>
              <td className="task-batch-padding-180 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_BUFFER_ZONE' })}
              </td>
              <td className="task-batch-padding-180 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_NOZZLE_NAME' })}
              </td>
              <td className="task-batch-padding-200 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_DRIFT_REDUCTION' })}
              </td>
              <td className="task-batch-padding-220 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_DRIVING_SPEED' })}
              </td>
              <td className="task-batch-padding-220 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_PRESSURE' })}
              </td>
              <td className="task-batch-padding-220 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_NOZZLE_HEIGHT' })}
              </td>

              <td className="task-batch-padding-160 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_BUFFER_EXISTS' })}
              </td>
              <td className="task-batch-padding-160 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_LOCATION_DISTANCE' })}
              </td>
              <td className="task-batch-padding-220 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_CULTURE_SPRAY' })}
              </td>
              <td className="task-batch-padding-200 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_FIELD_AGE' })}
              </td>
              <td className="task-batch-padding-160 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_DEVELOPMENT_STAGE' })}
              </td>
              <td className="task-batch-padding-160 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_DISTANCE_SURFACE_WATER' })}
              </td>
              <td className="task-batch-padding-180 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_FOG_SPRAYER' })}
              </td>
              <td className="task-batch-padding-180 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_AMOUNT' })}
              </td>
              <td className="task-batch-padding task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_PTO_SPEED' })}
              </td>
              <td className="task-batch-padding-180 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_NOZZLES_PROVIDE' })}
              </td>
              <td className="task-batch-padding-180 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_DAMPER_SET' })}
              </td>
              <td className="task-batch-padding-180 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_ONE_END_CLOSED' })}
              </td>
              <td className="task-batch-padding-200 task-batch-right-border">
                {intl.formatMessage({ id: 'WATER_JOURNAL_SPRAYING_TECHNIQUE' })}
              </td>
              <td className="task-batch-padding task-batch-right-border">
                {intl.formatMessage({ id: 'TASK_BATCH_ASSIGNEE' })}
              </td>
              <td className="task-batch-padding">
                {intl.formatMessage({ id: 'TASK_BATCH_NOTE' })}
              </td>
            </tr>
          </thead>
          <tbody className="task-batch-font-size task-batch-border-top">
            {this.getValueList()}
          </tbody>
        </table>
      </div>
      <div className="mybutton">
        <span className="feedback">
          <ActionButton onClick={() => handleSubmit(this.handleSubmit)}>
            {intl.formatMessage({ id: 'update' })}
          </ActionButton>
        </span>
      </div>
      <div className="fixed-button-task">
        <span className="padding-right-heavy-task">&nbsp;</span>
      </div>
      </form> :
      <div className='padding-top-no-content '>
        {actId !== null && intl.formatMessage({ id: 'NO_TASK_FOUND' })}
      </div>
    );
  }
}

function validate(values, props) {
  let errors = {};
  const { taskList } = props;
  taskList.map((list, index) =>
    errors = validatingFields(values, index, props, errors, list)
  );
  return errors;
}

function validatingFields(values, index, props, errors, list) {
  const { intl } = props;
  const dynamicDataObj = JSON.parse(list.dynamicData);
  if(!values[`startTime_${index}`]) {
    errors[`startTime_${index}`] = <FormattedMessage id="invalid" children={intl.formatMessage({ id: "invalid" })} />
  }
  if(!values[`endTime_${index}`]) {
    errors[`endTime_${index}`] = <FormattedMessage id="invalid" children={intl.formatMessage({ id: "invalid" })} />
  }
  if(values[`startTime_${index}`] && values[`endTime_${index}`]) {
    let startTimeSplit = values[`startTime_${index}`].split(" ");
    let endTimeSplit = values[`endTime_${index}`].split(" ");
    let startTime = getValueByDateAndTime(startTimeSplit[0],startTimeSplit[1]);
    let endTime = getValueByDateAndTime(endTimeSplit[0],endTimeSplit[1]);
    if(startTime > endTime) {
      errors[`endTime_${index}`] = <FormattedMessage id="invalid" children={intl.formatMessage({ id: "invalid" })} />
    }
  }
  if(values[`startTime_${index}`] && !isMatch(values[`startTime_${index}`], 'dd.MM.yyyy HH:mm')) {
    errors[`startTime_${index}`] = <FormattedMessage id="invalid" children={intl.formatMessage({ id: "invalid" })} />
  }
  if(values[`endTime_${index}`] && !isMatch(values[`endTime_${index}`], 'dd.MM.yyyy HH:mm')) {
    errors[`endTime_${index}`] = <FormattedMessage id="invalid" children={intl.formatMessage({ id: "invalid" })} />
  }
  if(dynamicDataObj.pestiside_type && dynamicDataObj.pestiside_type.length > 0) {
    dynamicDataObj.pestiside_type.forEach((type, indexx) => {
      errors = validatingPesticideFields(values, index, indexx, errors, props)
    });
  }
  if(values[`waterAmount_${index}`] && !AmountValidation(values[`waterAmount_${index}`])) {
    errors[`waterAmount_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
  }
  if(values[`distanceToWaterBody_${index}`] && !AmountValidation(values[`distanceToWaterBody_${index}`])) {
    errors[`distanceToWaterBody_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
  }
  if(values[`distanceToWaterWell_${index}`] && !AmountValidation(values[`distanceToWaterWell_${index}`])) {
    errors[`distanceToWaterWell_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
  }
  if(values[`driftReduction_${index}`] && !AmountValidation(values[`driftReduction_${index}`])) {
    errors[`driftReduction_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
  }
  if(values[`maxDrivingSpeed_${index}`] && !AmountValidation(values[`maxDrivingSpeed_${index}`])) {
    errors[`maxDrivingSpeed_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
  }
  if(values[`pressure_${index}`] && !AmountValidation(values[`pressure_${index}`])) {
    errors[`pressure_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
  }
  if(values[`nozzleHeight_${index}`] && !AmountValidation(values[`nozzleHeight_${index}`])) {
    errors[`nozzleHeight_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
  }
  if(values[`cultureBySpray_${index}`] && !AmountValidation(values[`cultureBySpray_${index}`])) {
    errors[`cultureBySpray_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
  }
  if(values[`fieldAge_${index}`] && !AmountValidation(values[`fieldAge_${index}`])) {
    errors[`fieldAge_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
  }
  if(values[`amount_${index}`] && !AmountValidation(values[`amount_${index}`])) {
    errors[`amount_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
  }
  if(values[`ptoSpeed_${index}`] && !AmountValidation(values[`ptoSpeed_${index}`])) {
    errors[`ptoSpeed_${index}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
  }
  return errors;
}

function validatingPesticideFields(values, index, indexx, errors, props) {
  const { intl } = props;
  if(values[`pesticideAmount_${index}_${indexx}`] && !AmountValidation(values[`pesticideAmount_${index}_${indexx}`])) {
    errors[`pesticideAmount_${index}_${indexx}`] = <FormattedMessage id="DYNAMIC_SECTION_AMOUNT_VAL_MSG" children={intl.formatMessage({ id: "DYNAMIC_SECTION_AMOUNT_VAL_MSG" })} />
  }
  return errors;
}

function handleFocusOnError(errors, errorFieldsState) {
  const errorFields = Object.keys(errors);
  const stateErrorFields = errorFieldsState && Object.keys(errorFieldsState);

  errorFieldsState && stateErrorFields.forEach((field) => {
    const element = document.getElementsByName(field)[0];
    if (element) {
      element.style.border = '';
      let errorMessageElement = document.getElementById(`${field}-error`);
      errorMessageElement.innerHTML = '';
    }
  });

  errorFields.forEach((field) => {
    const element = document.getElementsByName(field)[0];
    if (element) {
      element.style.border = '1px solid red';
      let errorMessageElement = document.getElementById(`${field}-error`);
      if (!errorMessageElement) {
        errorMessageElement = document.createElement('span');
        errorMessageElement.id = `${field}-error`;
        errorMessageElement.className = 'error-text';
        errorMessageElement.style.color = 'red';
        errorMessageElement.style.fontSize = '12px';
        errorMessageElement.style.marginTop = '4px';
        element.parentNode.insertBefore(errorMessageElement, element.nextSibling);
      }
      errorMessageElement.innerHTML = errors[field].props.children;
    }
  });

  // Focus on the first error field
  if (errorFields.length > 0) {
    const firstErrorField = errorFields[0];
    const firstElement = document.getElementsByName(firstErrorField)[0];
    if (firstElement) {
      firstElement.focus();
    }
  }
}

const mapStateToProps = state => ({
  formValues: getFormValues('BatchTask')(state),
  activeFarm: state.farmList.activeFarm,
  taskDetails: state.taskDetails
});

const mapDispatchToProps = dispatch => ({
  createTask: (taskData, type, taskComplete, farmId, status) => dispatch(taskActions.createUpdateTask(taskData, type, taskComplete, farmId, status, true, 3)),
});

export default injectIntl(
  reduxForm({
    form: 'TemplatePesticide',
    validate: () => ({}),
    touchOnBlur: false,
    touchOnChange: false
  })(withRouter(connect(mapStateToProps, mapDispatchToProps)(TemplatePesticide)))
);
