import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { COMPLETED } from 'constants/index';
import { fetchTaskGpsPoints } from 'actions/Tasks';
import { EanaCreateMap } from 'utils/map';

class DrivingPattern extends React.Component {
  constructor(props) {
    super(props);
    this.patternMap = new EanaCreateMap();
    this.gpsMap = React.createRef();
  }

  componentDidMount = () => {
    const { taskId, taskStatus, GetGpsData, taskGpsPoints } = this.props;
    if(taskId !== 'create') {
      GetGpsData(taskId);
    }
    if (taskStatus === COMPLETED && taskGpsPoints?.tasksGps?.taskGPSDataId === taskId) {
      this.drawPattern(taskGpsPoints.tasksGps.gpsPoints);
    }
  };

  componentDidUpdate(prevProps) {
    const { taskGpsPoints, taskStatus } = this.props;
    if (taskStatus === COMPLETED && prevProps.taskGpsPoints?.tasksGps !== taskGpsPoints.tasksGps) {
      this.drawPattern(taskGpsPoints.tasksGps.gpsPoints);
    }
  }

  drawPattern = latLng => {
    if (this.gpsMap.current && latLng?.length > 0) {
      const latLngValues = latLng.map(data => [data.lattitude, data.longitude]);
      this.patternMap.drawPolyine(this.gpsMap.current.id, latLngValues);
    }
  };

  render() {
    const { taskGpsPoints } = this.props;
    const show = taskGpsPoints?.tasksGps?.gpsPoints?.length > 0 ? '' : 'hide';
    return (
      <div className={`section-item ${show}`}>
        <div
          className="task-driving-pattern"
          id="gpsMap"
          ref={this.gpsMap}
          style={{ width: '100%', height: '500px' }}
        />
      </div>
    );
  }
}
const mapStateToProps = state => ({
  taskList: state.TaskList.data,
  taskGpsPoints: state.TaskList.gpsData
});

const mapDispatchToProps = dispatch => ({
  GetGpsData: taskId => dispatch(fetchTaskGpsPoints(taskId))
});

export default injectIntl(withRouter(connect(mapStateToProps, mapDispatchToProps)(DrivingPattern)));
