import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import * as getUserActivitiesAction from 'actions/UserActivities';
import * as manageKartStyle from 'mui-components/settings/kart/ManageKartStyle';
import Loader from 'components/Loader';
import { Box, Typography } from '@mui/material'

class ManageKart extends React.Component {
  componentDidMount() {
    const { activeFarm, getUserActivities } = this.props;
    if (activeFarm) {
      getUserActivities(activeFarm.farm.id);
    }
  }

  componentDidUpdate(prevProps) {
    const { activeFarm, getUserActivities } = this.props;
    if (activeFarm !== prevProps.activeFarm) {
      getUserActivities(activeFarm.farm.id);
    }
  }

  getCategoryActivities = (category) => {
    /*const { userActivitiesList, openNewTask, userInfo } = props;
    let categoryActivities = null;
    if(category === 'harvest') { categoryActivities = userActivitiesList.harvest; }
    if(category === 'soil') { categoryActivities = userActivitiesList.soil; }
    if(category === 'fertilization') { categoryActivities = userActivitiesList.fertilization; }
    if(category === 'others') { categoryActivities = userActivitiesList.others; }
    if (categoryActivities.activities?.length > 0) {*/
      return (
        <Box>
          <Box sx={{ padding: '20px 10px 5px 20px', display: 'flex', justifyContent: 'flex-start' }}>
            <Typography style={manageKartStyle.modalTitleSubHeader}>sdsdsd</Typography>
          </Box>
          <Box>
            <Box style={manageKartStyle.toggleMenu}>
              <Typography style={manageKartStyle.toggleMenuContent}>sdsdsd</Typography>
              <Typography>fdfdf</Typography>
            </Box>
            {/*categoryActivities.activities.map((userActivities) => {
              return userActivities.active && (
                <Box sx={{ "&:hover": { backgroundColor: "#E6E9E8" }}} style={mapboxStyle.lastUsedBoxLeft} onClick={() => {mixpanel.identify(userInfo.data.id); mixpanel.track(KART_SELECT_TASK); openNewTask(userActivities, selectedPolygonId, clickedPolygonName);}}>
                  <Box sx={{ paddingTop: '8px' }}>
                    <AddIcon width="24" height="24"/>
                  </Box>
                  <Typography style={mapboxStyle.lastUsedBoxTitle} key={userActivities.id}>{userActivities.activityName}</Typography>
                </Box> )
              })
            */}
          </Box>
        </Box>
      );
    {/*}*/}
    return null;
  };

  render() {
    const { userActivities } = this.props;
    const { isLoading, userActivitiesList } = userActivities;
    console.log('userActivities: ',userActivities);
    return (
      <div className="asset-list-wrapper">
        {isLoading && <div style={{ backgroundColor: '#FFFFFF', width: '100%'}}><Loader styleName="fixed-loader" /></div>}
        <Box>
          {this.getCategoryActivities('others')}
        </Box>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getUserActivities: (farmId) => dispatch(getUserActivitiesAction.getUserActivities(farmId))
});

const mapStateToProps = state => ({
  userInfo: state.userDetails,
  activeFarm: state.farmList.activeFarm,
  userActivities: state.userActivities
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ManageKart));
