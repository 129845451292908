import { USER_ACTIVITIES_URL } from 'constants/ApiUrl';
import {
  USER_ACTIVITIES_LOADING,
  USER_ACTIVITIES_SUCCESS,
  USER_ACTIVITIES_FAILURE
} from 'constants/store/UserActivities';
import apiRequest from 'utils/ApiRequest';
import { GetLanguageCode } from 'utils/index';

export const getUserActivities = (farmId) => async (dispatch, getState) => {
  dispatch({ type: USER_ACTIVITIES_LOADING, payload: true });
  const { preferredLanguage } = getState().userDetails?.data;
  await dispatch(
    apiRequest({
      url: `${USER_ACTIVITIES_URL}/${farmId}/user-activities?language=${GetLanguageCode(
        preferredLanguage
      )}`,
      method: 'get'
    })
  )
    .then(response => {
      dispatch({ type: USER_ACTIVITIES_SUCCESS, payload: response.data });
    })
    .catch(error => {
      dispatch({ type: USER_ACTIVITIES_FAILURE, payload: error });
    });
};