import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Header from 'mui-components/header';
import { Box, Typography } from '@mui/material'
import { TASK_TASKLIST, TASK_BATCH } from 'constants/index';
import TaskList from 'containers/tasks/tasklist';
import TaskBatch from 'containers/tasks/batch';
import { isMobile } from 'react-device-detect';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={index === 0 ? 'customFieldTabDiv' : 'customFertilizerTabDiv'}
    >
      {value === index && (
        <Box sx={{ p: 5 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

class Tasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: null,
      value: 0
    };
  }

  componentDidMount = () => {
    const { location, activeFarm, history } = this.props;
    if (!activeFarm) {
      history.push('/settings');
    }
    if (location.pathname.includes(TASK_BATCH)) {
      this.setState({
        selected: TASK_BATCH,
        value: 1
      });
    } else {
      this.setState({
        selected: TASK_TASKLIST,
        value: 0
      });
    }
  };

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      let newSelected;
      let newValue;
  
      // Set the selected tab and value based on the current pathname
      if (location.pathname.includes(TASK_BATCH)) {
        newSelected = TASK_BATCH;
        newValue = 1;
      } else {
        newSelected = TASK_TASKLIST;
        newValue = 0;
      }
  
      // Update the state only if the selected value or the value state is different
      if (newSelected !== this.state.selected || newValue !== this.state.value) {
        this.setState({
          selected: newSelected,
          value: newValue
        });
      }
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
    const { history } = this.props;    
    if(newValue === 1) {
      this.setState({ selected: TASK_BATCH, value: newValue });
      history.push("/task-batch");
    } else {
      this.setState({ selected: TASK_TASKLIST, value: newValue });
      history.push("/tasks");
    }
  };

  render() {
    const { intl, location } = this.props;
    const { value } = this.state;
    return (
      <div className={`${isMobile ? 'main-content-mobile' : 'main-content'}`} style={{ paddingTop: `${isMobile ? '70px' : ''}`}}>
        {(location.pathname).includes('tasks') && <Header bcTitle={intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_5' })} />}
        {(location.pathname).includes('task-batch') && <Header bcTitle={intl.formatMessage({ id: 'MUI_NAVIGATION_BAR_6' })} />}
        <div className="settings-tabs">
          <Box sx={{ borderBottom: 1, borderColor: 'divider', flexGrow: 0 }}>
            {/*<Grid container justifyContent="flex-end">
              <Grid item md='12'>
                <Tabs variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  value={value} onChange={this.handleChange} className='mui-tabs'>
                  <Tab style={tabStyle} label={intl.formatMessage({ id: 'TASK_TASKLIST' })} {...a11yProps(0)} />
                  <Tab style={tabStyle} label={intl.formatMessage({ id: 'TASK_BATCH' })} {...a11yProps(1)} sx={{wordSpacing:"15%"}} />
                </Tabs>
              </Grid>
            </Grid>*/}
          </Box>
          <CustomTabPanel value={value} index={0}>
            <TaskList />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <TaskBatch />
          </CustomTabPanel>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm
});
export default injectIntl(withRouter(connect(mapStateToProps)(Tasks)));