import * as React from 'react';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import SubscriptionCard from 'mui-mobile-components/settings/subscription/SubscriptionCard';

class SubscriptionList extends React.Component {
  renderList = () => {
    const { subscriptionList } = this.props;
    if (subscriptionList?.length > 0) {
      return (
        subscriptionList.map((subscription) => {
          return (
            <ul key={subscription.id} style={{ paddingLeft: '5px', paddingRight: '5px', paddingTop: '5px' }}>
              <SubscriptionCard subscription={subscription} />
            </ul>
          );
        })
      );
    }
    return null;
  };

  render() {
    return (
      <div className="vehicle-list-details">
        {this.renderList()}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
});

const mapStateToProps = state => ({
  activeFarm: state.farmList.activeFarm,
  hasSupportAdminRole: state.SupportAdminRole
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SubscriptionList));
