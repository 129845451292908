import React, { useState } from "react";
import { TextField } from '@mui/material';

const MuiTextFieldArea = (props) => {
  const {defaultLabel, defaultValue, fieldName, onChange} = props;
  const [value, setValue] = useState(defaultValue || "");

  const handleChange = (e) => {
    setValue(e.target.value);
    if (onChange) {
      onChange(fieldName, e.target.value);
    }
  };

  return (
    <TextField
      variant="outlined"
      label={defaultLabel}
      value={value}
      onChange={handleChange}
      multiline
      rows={4}
      fullWidth
      sx={{
        '& textarea': {
          height: '112px',
        },
        '& label': {
          color: '#3F4949',
          fontFamily: 'Inter',
        },
        '& label.Mui-focused': {
          color: '#00696D',
          fontFamily: 'Inter'
        }
      }}
    />
  );
};

export default MuiTextFieldArea;