import {
  GET_VEHICLE_LIST,
  GET_VEHICLE_LIST_FAILURE,
  GET_EQUIPMENT_LIST,
  GET_EQUIPMENT_LIST_FAILURE,
  CREATE_TASK_SUCCESS,
  CREATE_TASK_FAILURE,
  STORE_TASK_DETAILS,
  GET_ENTITY_DETAILS_SUCCESS,
  GET_ENTITY_DETAILS_FAILURE,
  CREATE_ASSET_ENTITY_SUCCESS,
  CREATE_ASSET_ENTITY_FAILURE,
  SUBMIT_TASK_IS_LOADING,
  NULLFIY_SUCCESS,
  GET_ALL_ASSETS,
  GET_ALL_ASSETS_FAILURE,
  CREATE_ASSET_ENTITY_IS_LOADING,
  NULLIFY_CREATE_ASSET_ENTITY_SUCCESS,
  EDIT_TASK_INFO,
  GET_ASSET_ENTITY_IS_LOADING,
  ASSET_SORT_BY_OPTION,
  DELETE_ASSET_IS_LOADING,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAILURE,
  VEHICLES,
  EQUIPMENTS,
  NULLIFY_ASSET_ENTITY_DETAILS
} from 'constants/index';
import _ from 'lodash';
import { AssetSortingOptions } from 'constants/DropdownAndTranslationOptions';
import {
  TASK_UPDATE_DURATION_LOADING,
  TASK_UPDATE_DURATION_SUCCESS,
  TASK_UPDATE_DURATION_FAILURE
} from 'constants/store/Tasks';

const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  createAssetEntityIsLoading: false,
  getAssetEntityIsLoading: false,
  createAssetEntityIsFailure: false,
  createAssetEntityIsSuccess: false,
  createAssetEntityData: null,
  vehicleSortBy: { value: AssetSortingOptions[0].value, label: AssetSortingOptions[0].transLbl },
  equipmentSortBy: {
    value: AssetSortingOptions[0].value,
    label: AssetSortingOptions[0].transLbl
  },
  isAssetDeleteSuccess: false,
  isCompletedSuccess: false,
  isCompletedFailure: false,
};

export const TasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_VEHICLE_LIST: {
      return { ...state, vehicleList: action.payload };
    }
    case GET_VEHICLE_LIST_FAILURE:
      return { ...state };
    case GET_EQUIPMENT_LIST: {
      return {
        ...state,
        equipmentList: action.payload
      };
    }
    case GET_EQUIPMENT_LIST_FAILURE:
      return { ...state };
    case CREATE_TASK_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isSuccess: true
      };
    case CREATE_TASK_FAILURE:
      return {
        ...state,
        isFailure: action.payload.bool,
        errorMessage: action.payload.errorMsg
      };
    case STORE_TASK_DETAILS: {
      const taskInfo = state.taskInfo || {};
      const recObj = action.payload;
      Object.keys(recObj).forEach(key => {
        taskInfo[key] = recObj[key];
      });
      return { ...state, taskInfo };
    }
    case GET_ENTITY_DETAILS_SUCCESS: {
      const filteredRes = _.orderBy(
        _.uniqBy(action.payload, 'brand'),
        [data => data.brand.toLowerCase()],
        ['asc']
      );
      return { ...state, assetDetails: filteredRes, assetResponse: action.payload };
    }
    case GET_ENTITY_DETAILS_FAILURE:
      return { ...state, assetDetails: [], assetResponse: [] };
    case NULLIFY_ASSET_ENTITY_DETAILS:
      return { ...state, assetDetails: [], assetResponse: [] };
    case CREATE_ASSET_ENTITY_SUCCESS:
      return {
        ...state,
        createAssetEntityData: action.payload,
        createAssetEntityIsSuccess: true,
        actionTriggered: action.payload.actionTriggered
      };
    case CREATE_ASSET_ENTITY_FAILURE:
      return {
        ...state,
        createAssetEntityIsFailure: action.payload.bool,
        createAssetEntityErrorMsg: action.payload.errorMsg
      };
    case SUBMIT_TASK_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case NULLFIY_SUCCESS:
      return { ...state, isSuccess: false, errorMessage: '' };
    case GET_ALL_ASSETS: {
      let { allVehicles, allEquipments } = state;
      const { category, data } = action.payload;
      if (category === 'vehicle') {
        allVehicles = data;
      } else if (category === 'equipment') {
        allEquipments = data;
      }
      return { ...state, allVehicles, allEquipments };
    }
    case GET_ALL_ASSETS_FAILURE:
      return { ...state };
    case CREATE_ASSET_ENTITY_IS_LOADING:
      return { ...state, createAssetEntityIsLoading: action.payload };
    case GET_ASSET_ENTITY_IS_LOADING:
      return { ...state, getAssetEntityIsLoading: action.payload };
    case NULLIFY_CREATE_ASSET_ENTITY_SUCCESS:
      return {
        ...state,
        isSuccess: false,
        createAssetEntityData: null,
        createAssetEntityIsSuccess: false,
        createAssetEntityIsFailure: false
      };
    case EDIT_TASK_INFO:
      return { ...state, editTaskObj: action.payload };
    case ASSET_SORT_BY_OPTION:
      return {
        ...state,
        vehicleSortBy:
          action.payload.assetType === VEHICLES ? action.payload.option : state.vehicleSortBy,
        equipmentSortBy:
          action.payload.assetType === EQUIPMENTS ? action.payload.option : state.equipmentSortBy
      };
    case DELETE_ASSET_IS_LOADING:
      return { ...state };
    case DELETE_ASSET_SUCCESS:
      return { ...state, isAssetDeleteSuccess: action.payload };
    case DELETE_ASSET_FAILURE:
      return { ...state };
    case 'CLEAR_TASK_OBJ':
      return { ...state, editTaskObj: {}, taskInfo: {}, isSuccess: false };
    case TASK_UPDATE_DURATION_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case TASK_UPDATE_DURATION_SUCCESS:
      return {
        ...state,
        isLoading: false
      };
    case TASK_UPDATE_DURATION_FAILURE:
      return {
        ...state,
        isLoading: false
      };
    default:
      return { ...state };
  }
};

export default TasksReducer;
